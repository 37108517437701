import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Button } from 'react-bootstrap';
// import machine_desing from '../../asset/Mill-Liner-Solutions-PulpMax-Composite-Mill-Liners-Master.webp';
// import side_img from '../../asset/Mill_load_mill_liner.webp';
// import application_diagram from '../../asset/PulpMax-Mill-Liner---Exploded-View.webp';
import Table from 'react-bootstrap/Table';
import "../../css/singleproduct.css"

import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";

const ProductTwo = () => {
    const [product, setProduct] = useState([]);

    function capitalizeAndReplace(text) {
        if(text){
            return text
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            }
        else{
            return ""
        }
    }

    const bannerData = {
        imgurl: '../images/industries.jpg',
        title: product.product_name,
        // description: capitalizeAndReplace(product.product_category),
    };

    const { slug } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        let isMounted = true;

        console.log("test")
        axios.get(`https://tega.codtrees.com/api/admin/products/${slug}`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    console.log(res.data.product);
                    setProduct(res.data.product);
                }
            }
        })
    }, [slug]);

    function removeExtensionAndSuffix(filename) {
        if (filename) {
            let filenameWithoutExtension = filename.replace(/\.[^/.]+$/, '');
            filenameWithoutExtension = filenameWithoutExtension.replace(/(\s*\(1\))+$/, '');
            return filenameWithoutExtension;
        }
        else {
            return filename
        }

    }

    const pdf_name = removeExtensionAndSuffix(product.product_file);


    const handleClick = () => {
        const pdfUrl = `https://tega.codtrees.com/public/Assets/File/products/${pdf_name}.pdf`;

        window.open(pdfUrl, '_blank');
    };




    return (
        <>
            <InternalBanner{...bannerData} />
            <Container>
                <div className="page-content product-single">
                    <h2 className="bbefore"><span>{product.product_name}</span></h2>
                    <Row className="product_sd d-flex align-items-center">
                        <Col md={6} className="image">
                            <img src={`https://tega.codtrees.com/public/Assets/Image/products/${product.product_image}`} alt="product" className="img-fluid" />
                        </Col>
                        <Col md={6} className="sd">
                            <p className="benefits">Key Benefits <i className="fas fa-arrow-right"></i></p>
                            <p className="mb-0 text" dangerouslySetInnerHTML={{ __html: product.short_description }} />
                        </Col>
                    </Row>
                    <Row>

                        <p dangerouslySetInnerHTML={{ __html: product.product_description }}></p>
                    </Row>

                    <Row className="pdf_row">
                        <p>Download:</p>

                        <Row className="pdf_section">
                            <Col md={2} className="icon">
                                <i class="fa-regular fa-file-pdf fa-2x"></i>
                            </Col>

                            <Col md={9} className="name">
                                {pdf_name}
                            </Col>

                            <Col md={1} className="arrow">
                                <button type="button" className="btn" onClick={handleClick} data-bs-toggle="tooltip" data-bs-placement="top" title="click to download">
                                    <i className="fas fa-arrow-down"></i>
                                </button>
                            </Col>
                        </Row>

                    </Row>


                </div>
            </Container>
        </>
    );
}

export default ProductTwo;