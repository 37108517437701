import React from "react";
import { Link } from "react-router-dom";
import InternalBanner from "../components/global/InternalBanner";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useRef, useState,useEffect } from "react";


const OurOffering = () => {
  const bannerData = {
    imgurl: "images/industries.jpg",
    title: "Our Offering",
    // description: "This is Our Offering page",
  };

  useEffect(() => {
    document.title = "Our Offering | Tega Mcnally";
  }, []);

  const ProductsComponent = () => {
    const products = [
      { id: 1, title: "Grinding Equipments", imageUrl: "images/our-facilities/image.png",slugpro:"/category/grinding-equipments"},
      { id: 2, title: "Screening Equipments", imageUrl: "images/our-facilities/image1.png", slugpro:"/category/screening-equipments" },
      { id: 3, title: "Feeding Equipments", imageUrl: "images/our-facilities/feeding.png", slugpro:"/category/feeding-equipments"},
      { id: 4, title: "Crushing Equipments", imageUrl: "images/our-facilities/crushing.png", slugpro:"/category/crushing"},
      { id: 5, title: "Job Shop", imageUrl: "images/our-facilities/job_shop.jpg",slugpro:"/category/job-shop"},
      { id: 6, title: "Construction Equipments", imageUrl: "images/our-facilities/construction_eqp.png",slugpro:"/category/construction-equipments"},
      { id: 7, title: "Equipment for Steel Plants", imageUrl: "images/our-facilities/steel_plant.jpg", slugpro:"/category/equpment-for-steel-plants"},
      { id: 8, title: "Process Plant Equipments", imageUrl: "images/our-facilities/process_plant.png",slugpro:"/category/process-plant-equipments"},
      { id: 9, title: "Ash Handling Equipment", imageUrl: "images/our-facilities/ash_handling.jpg", slugpro:"/category/ash-handling-equipment"},
      { id: 10, title: "Material Handling Equipment", imageUrl: "images/our-facilities/material_handeling.jpg", slugpro:"/category/material-handling-equipment" },
    ];

    return (
      <>
        <InternalBanner {...bannerData} />
        <Container>
          <div className="page-content">
            <Row xs={1} md={2} lg={4} className="g-4">
              {products.map((product) => (
                <Col key={product.id}>
                  <Card className="product-cards">
                    <div className="image-overlay"></div>
                    <Card.Img variant="top" src={product.imageUrl} />
                    <Card.Body className="product-body">
                      <h4>{product.title}</h4>
                      {/* <p className="mb-0">{product.slugpro}</p> */}
                    </Card.Body>
                    <div className="seemore text-center">
                      <Link to={`/product${product.slugpro}`}>
                        See More
                      </Link>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </>
    );
  };

  return <ProductsComponent />;
};

export default OurOffering;
