import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import InternalBanner from "../../components/global/InternalBanner";

const CategoryArchive = () => {
  const { product_category } = useParams();

  // Mapping for titles
  const titlesMapping = {
    "crushing": "Crushing Equipments",
    "grinding-equipments": "Grinding Equipments",
    "screening-equipments": "Screening Equipments",
    "feeding-equipments": "Feeding Equipments",
    "job-shop": "Job Shop",
    "construction-equipments": "Construction Equipments",
    "equpment-for-steel-plants": "Equipments for Steel Plants",
    "process-plant-equipments": "Process Plant Equipments",
    "ash-handling-equipment": "Ash Handling Equipments",
    "material-handling-equipment": "Material Handling Equipments",
  };

  const titles = titlesMapping[product_category] || "";

  const bannerData = {
    imgurl: "/images/industries.jpg",
    title: titles
  };

  const [products, setProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    let isMounted = true;

    axios.get(`${apiUrl}/api/admin/product/${product_category}`).then(res => {
      if (isMounted && res.data.status === 200) {
        setProducts(res.data.product);
      }
    });

    // Cleanup function to prevent state updates after component unmount
    return () => {
      isMounted = false;
    };
  }, [product_category]); // Include product_category as a dependency

  return (
    <>
      <InternalBanner {...bannerData} />
      <Container>
        <div className="page-content">
          <Row xs={1} md={2} lg={4} className="g-4">
            {products.map((prod, index) => (
              <Col key={prod.id}>
                <Card className="product-cards">
                  <div className="image-overlay"></div>
                  <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${prod.product_image}`} />
                  <Card.Body className="product-body">
                    <h4>{prod.product_name}</h4>
                  </Card.Body>
                  <div className="seemore text-center">
                    <Link to={`/single-product/${prod.slug}`}>
                      See More
                    </Link>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default CategoryArchive;
