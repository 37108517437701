import React from "react";
import InternalBanner from "../components/global/InternalBanner";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRef, useState,useEffect } from "react";

const Industries = () => {

    useEffect(() => {
      document.title = "Industries | Tega Mcnally";
    }, []);

    const bannerData={
        imgurl:'images/industries.jpg',
        title:"Industries",
        description:"This is Industries page",
    };

    return(
        <>
        <InternalBanner{...bannerData}/>
        <Container>
        <div className="page-content">
        <Row>
        <Col sm={12} md={4}>
          <Card>
            <Card.Img className="shadow" variant="top" src="images/industrygraphic.jpg" />
            <Card.Body className="text-center">
              <h4> <Link to="/industry-one">Industry One</Link></h4>
              
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Img className="shadow" variant="top" src="images/industrygraphic.jpg" />
            <Card.Body className="text-center">
              <h4><Link to="/industry-two">Industry Two</Link></h4>
              
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Img className="shadow" variant="top" src="images/industrygraphic.jpg" />
            <Card.Body className="text-center">
              <h4><Link to="/industry-one">Industry Three</Link></h4>
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
        </div>
        </Container>
        </>
    );
}   

export default Industries;