import React, { useEffect, useState } from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../../css/blogs-single.css";
import { Helmet } from "react-helmet";

const apiUrl = process.env.REACT_APP_API_URL;

function BlogSingle() {
  const [blog, setBlog] = useState(null);
  const [blogsAll, setBlogsAll] = useState([]);
  const [blogCategory, setBlogCategory] = useState([]);
  const [catName, setCatName] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [blogData, setBlogData] = useState([]);
  const { slug } = useParams();
  const navigate = useNavigate();

  // Fetch the blog data based on slug
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const res = await axios.get(`${apiUrl}/api/admin/blog/${slug}`);
        if (res.data.status === 200) {
          setBlog(res.data.blog);
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    if (slug) {
      fetchBlog();
    }
  }, [slug]);

  // Fetch all blogs and categories
  useEffect(() => {
    const fetchBlogsAndCategories = async () => {
      try {
        const blogsRes = await axios.get(`${apiUrl}/api/admin/blog/category`);
        setBlogsAll(blogsRes.data.content);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Error fetching blogs: 404 Not Found");
        } else {
          console.error("Error fetching blogs:", error);
        }
      }

      try {
        const categoriesRes = await axios.get(`https://tega.codtrees.com/api/blogcat_getdata`);
        setBlogCategory(categoriesRes.data.content);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Error fetching categories: 404 Not Found");
        } else {
          console.error("Error fetching categories:", error);
        }
      }
    };

    fetchBlogsAndCategories();
  }, []);

  // Fetch blog data based on category
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const res = await axios.post(`https://tega.codtrees.com/api/admin/blogcategory/category`, { category: catName });
        setBlogData(res.data.blogs);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Error fetching blog data: 404 Not Found");
        } else {
          console.error("Error fetching blog data:", error);
        }
      }
    };

    if (catName) {
      fetchBlogData();
    }
  }, [catName]);

  const handleClick = (cat_name) => {
    setActiveCategory(cat_name);
    navigate("/blogs", { state: { cate_type: cat_name } });
  };

  const getCategoryClass = (cat_name) => {
    return cat_name === activeCategory ? "active-category" : "";
  };

  const handleFilterBlog = (id) => {
    if (id === null) {
      setBlog(blogData);
    } else {
      const filtered = blogData.filter(obj => obj.category === null ? null : obj.category.includes(id));
      setBlog(filtered.length > 0 ? filtered : blogData);
    }
  };

  const bannerData = {
    imgurl: "../images/industries.jpg",
  };

  return (
    <>
     <Helmet>
     {/* <meta name="title" content={`${blog.product_name} | Tega Mcnally`} /> */}

    <title>{`${blog.blog_title} | Tega Mcnally`}</title>
    <meta name="title" content={`${blog.blog_title} | Tega Mcnally`} />
    <meta name="description" content={`${blog.blog_title}`} />
</Helmet>
      <InternalBanner {...bannerData} />
      <Container className="single-blog-page blog-container">
        <div className="page-content">
          <Row>
            <Col md={3}>
              <div className="filter-container single-blog-side-container">
                <ul className="pdflistab shadow">
                  <div className="tile__title">Categories</div>
                  <li className={`radio-body ${getCategoryClass("")}`}>
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                    &nbsp;
                    <input
                      type="radio"
                      id="all_cate"
                      name="fav_language"
                      defaultValue="CSS"
                      onChange={() => handleFilterBlog(null)}
                      defaultChecked
                    />
                    &nbsp;
                    <label htmlFor="all_cate" onClick={() => handleClick("")}>All Blogs</label>
                    <br />
                  </li>
                  {blogCategory.length > 0 ? (
                    blogCategory.map((cate, index) => (
                      <li key={index} className={`radio-body ${getCategoryClass(cate.category_name)}`}>
                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                        &nbsp;
                        <input
                          type="radio"
                          id={`${cate.id}`}
                          name="fav_language"
                          defaultValue="CSS"
                          onChange={() => handleFilterBlog(cate.id)}
                          onClick={() => handleClick(cate.category_name)}
                        />
                        &nbsp;
                        <label htmlFor={`${cate.id}`}>{cate.category_name}</label>
                        <br />
                      </li>
                    ))
                  ) : (
                    <li>No categories available</li>
                  )}
                </ul>
              </div>
            </Col>
            <Col md={6} style={{ marginTop: "43px" }}>
              {blog ? (
                <>
                  <img
                    src={`${apiUrl}/public/Assets/Image/blog/${blog.blog_image}`}
                    className="img-fluid blogc"
                    alt={blog.blog_title}
                  />
                  <br />
                  <h3>{blog.blog_title}</h3>
                  <div
                    className="blog-content mt-4"
                    dangerouslySetInnerHTML={{ __html: blog.blog_description }}
                  ></div>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Col>
            <Col md={3}>
              <div className="single-blog-side-container no-margin-buttom">
                <h3 className="bbefore">
                  <span>Recent Blogs</span>
                </h3>
                <div>
                  <Row className="mb-4 blogcatp">
                    <Col md={12} className="recent-blogs-blog">
                      {blog ? (
                        <>
                          <img
                            src={`${apiUrl}/public/Assets/Image/blog/${blog.blog_image}`}
                            className="img-fluid blogc"
                            alt={blog.blog_title}
                          />
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                      <h3>
                        <Link to={`/blog/${blog?.slug}`} target="_blank">
                          <p>{blog?.blog_title}</p>
                        </Link>
                      </h3>
                    </Col>
                  </Row>
                  <hr />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default BlogSingle;