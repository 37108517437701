import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Col, Row } from "react-bootstrap";
import { FaBalanceScale, FaLightbulb, FaHeart } from 'react-icons/fa';

const LifeTml = () => {

    const bannerData = {
        imgurl: '/images/industries.jpg',
        title: "Life @TML",
        description: "This is Life @TML page",
    };

    return (
        <>
            <InternalBanner {...bannerData} />
            <Container>
                <div className="page-content">
                    <p>We firmly uphold the belief that our workforce constitutes our most valuable asset. Our commitment extends to fostering an environment characterized by diversity, innovation, total quality management, and genuine care and respect for our employees. Central to our ethos is the recognition that diversity within our team greatly enhances our collective capabilities.</p>

                    <p> Irrespective of the geographical locations in which we operate globally, we take pride in our status as an equal opportunity employer. We unequivocally reject discrimination based on race, caste, religion, color, ancestry, gender, marital status, sexual orientation, age, nationality, ethnic origin, or disability. Our employment policies and practices are structured to ensure that all decisions pertaining to promotion, compensation, and other forms of recognition are made solely on the basis of merit.</p>

                    <Row className="mt-4 mb-4">
                        <Col md={4} className="d-flex">
                            <div className="meritcont text-center shadow d-flex flex-column">
                                <div className="iconwrap">
                                    <FaBalanceScale className="iconcar" />
                                </div>
                                <h5>Work Life Balance</h5>
                                <p>We hold the conviction that our employees can thrive and make their fullest contributions when they lead lives characterized by happiness, health, and balance.</p>
                            </div>
                        </Col>
                        <Col md={4} className="d-flex">
                            <div className="meritcont text-center shadow d-flex flex-column">
                                <div className="iconwrap">
                                    <FaLightbulb className="iconcar" />
                                </div>
                                <h5>Learn and Grow</h5>
                                <p>We are committed to fostering the growth and development of our employees at Tega Mcnally.</p>
                            </div>
                        </Col>
                        <Col md={4} className="d-flex">
                            <div className="meritcont shadow text-center d-flex flex-column">
                                <div className="iconwrap">
                                    <FaHeart className="iconcar" />
                                </div>
                                <h5>Employee Wellness Program</h5>
                                <p>Our Employee Wellness Program is designed to support our employees in every aspect of their lives.</p>
                            </div>
                        </Col>
                    </Row>

                    <p>We actively foster a high-performance culture and bolster it through a range of rewards and acknowledgments. Our commitment to providing a safe, healthy, clean, and ergonomic work environment extends to all our employees, contractors, and individuals impacted by our operations. Our aspiration is to set the standard for health and safety within the global steel industry.</p>
                </div>
            </Container>
        </>
    );
}

export default LifeTml;
