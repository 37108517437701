import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


const Quality = () => {
  const bannerData = {
    imgurl: "images/industries.jpg",
    title: "Quality Assurance",
    // description: "This is Company Overview page",
  };

  useEffect(() => {
    document.title = "Quality Assurance | Tega Mcnally";
  }, []);

  return (
    <>
      <InternalBanner {...bannerData} />

      <Container className="company_overview">
        <Row>
          <Col md={9}>
            <div className="colspac"></div>

            <Row>
              <Col>
                <h2 className="bbefore mt-2">
                  <span>Quality Assurance Surveillance</span>
                </h2>

                <div class="company-overview">
                  <div class="image-container">
                    <img src="https://themes.webdevia.com/backhoe-heavy-construction-equipment-rentals-machinery-wordpress-theme/wp-content/uploads/2019/06/moved-img-1@2x-620x480.jpg" alt="Moved Image" />
                    <div class="overlay"></div>
                  </div>
                  <div class="content-container">
                    {/* <div class="title">Company Overview</div> */}
                    <ul>
                      <li>Quality Assurance Department for TMML is effectively run by qualified and experienced engineers.</li>
                      <li>Our factories are certified under ISO 9001:2015 (Quality Management System).</li>
                      <li>A section of QA personnel located at the factories are trained in non-destructive testing in the field like Ultrasonic Testing, Radiography Testing, Magnetic Particle Testing and Liquid Penetration Testing. These people are qualified under ASNT Level -II/ Level-l in the above-mentioned areas.</li>
                      <li>The factories are equipped with non-destructive testing facilities for RT, UT, MPT & LPT; If required help is taken from the approved and reputed third party testing agencies that are also accredited by NABL.</li>
                      <li>The factories are equipped with good Testing Laboratory facilities for Chemical Analysis as well as Mechanical Testing. Most of the Calibration is done from external Laboratories accredited by NABL.</li>
                      <li>The QA personnel control the inspection and testing of the raw material - in process and final - for the products or equipment manufactured at the works.</li>
                      <li>Depending upon the situation TMML sometimes hires the services of globally known third party inspection agencies like Lloyds, Bureau Veritas, TUV Nord, Indian Register of Shipping etc.</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="colspac"></div>

            <Row>
              <Col>
                <h2 className="bbefore">
                  <span>Product Quality</span>
                </h2>

                <div class="company-overview">
                  <div class="">
                    {/* <div class="title">Company Overview</div> */}
                    <h5 className="bbefore">
                      <span>Quality Policy</span>
                    </h5>
                    <div class="subtitle">
                      <p> We shall consciously strive to upgrade our systems and technology so as to constantly improve our products and services to our customers.</p>
                    </div>
                    <h5 className="bbefore">
                      <span>Quality Objectives</span>
                    </h5>
                    <p>
                      We shall:
                    </p>
                    <ul>
                      <li>Consciously strive to upgrade our system and technology so as to continually improve our products and services to our customers and our quality management system.</li>
                      <li>Set measurable quality objectives at relevant functions & reviewed periodically.</li>
                      <li>Meet customer requirements and expectations consistently through meeting all applicable statutory, regulatory & other requirements.</li>
                      <li>Develop external service provider through continual monitoring their performance, needs & expectations.</li>
                    </ul>
                  </div>
                  <div class="image-container">
                    <img src="https://themes.webdevia.com/backhoe-heavy-construction-equipment-rentals-machinery-wordpress-theme/wp-content/uploads/2019/06/moved-img-1@2x-620x480.jpg" alt="Moved Image" />
                    <div class="overlay"></div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="colspac"></div>

            <Row>
              <Col>
                <h2 className="bbefore mt-2">
                  <span>Safety</span>
                </h2>

                <div class="company-overview">
                  <div class="image-container">
                    <img src="https://themes.webdevia.com/backhoe-heavy-construction-equipment-rentals-machinery-wordpress-theme/wp-content/uploads/2019/06/moved-img-1@2x-620x480.jpg" alt="Moved Image" />
                    <div class="overlay"></div>
                  </div>
                  <div class="content-container">
                    {/* <div class="title">Company Overview</div> */}
                    <p> We at TMML, resolve that safety is an integral part of our Industry. We have adequate safety measures built in our plants, in Product Maintenance functions. Our endeavour will always be to keep the plant in the safest of conditions. We are committed to provide a safe, clean and healthy work environment employee, ensuring compliance with all statutory laws and regulations.</p>
                    <p>
                      We Shall:
                    </p>
                    <ul>
                      <li>Constitute a Safety Committee Comprising Management Staff and workmen for creating a total awareness about safety at all levels.</li>
                      <li>Operate with the conviction that all accidents are preventable and aim for "Zero" accident.</li>
                      <li>Impart training regarding safe work practices from time to time to one and all and keep them informed about safety advancement.</li>
                      <li>Ensure that the Contractors, Transporters, other Agencies and Visitors to the factory abide by Safety Rules.</li>
                      <li>Provide personal protective equipment whenever and wherever required.</li>
                      <li>Encourage and create interest amongst all by organizing Safety Competitions, Slogans and Cartoon Contests.</li>
                      <li>Discharge our personal responsibility and cooperate and actively participate in maintaining and improving safety standards.</li>
                      <li>Follow safety rules, regulations, safe operating procedures / work methods that are designed to protect people and equipment from risk of injury or damage to property.</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>

          </Col>

          <Col md={3} className="flter_co">
            <div className="flter_co-inner">
              <ul className="filter_ul">
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="https://assets.devfolio.co/hackathons/69ed9492aa304d0e9b1203fa014df783/projects/0814509664d944d893dc749a4aa2e436/1d085d5f-5518-4882-abe3-4e09c493f296.jpeg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">


                        <Link to={"/about"} style={{ color: "white" }}>
                          About us
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="https://assets.devfolio.co/hackathons/69ed9492aa304d0e9b1203fa014df783/projects/0814509664d944d893dc749a4aa2e436/1d085d5f-5518-4882-abe3-4e09c493f296.jpeg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/manufacturing-facilities"} style={{ color: "white" }}>
                          Manufacturing Facilities
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="https://assets.devfolio.co/hackathons/69ed9492aa304d0e9b1203fa014df783/projects/0814509664d944d893dc749a4aa2e436/1d085d5f-5518-4882-abe3-4e09c493f296.jpeg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/quality-assurance"} style={{ color: "white" }}>
                          Company Overview
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </Col>

        </Row>
      </Container>





    </>
  );
};


export default Quality;
