import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './components/global/Navigation';
import HomeComponent from './pages/HomeComponent';
import AboutComponent from './pages/AboutComponent';
import { Footer } from './components/global/Footer';
import OurOffering from './pages/OurOffering';
import Services from './pages/Services';
import Career from './pages/Career';
import Sustainability from './pages/Sustainability';
import Resources from './pages/Resources';
import Contact from './pages/Contact';
import ConpmanyOverview from './pages/aboutchild/CompanyOvervew';
import Values from './pages/aboutchild/Values';
import Leadership from './pages/aboutchild/Leadership';
import Operational from './pages/aboutchild/Operational';
import Awards from './pages/aboutchild/Awards';
import Quality from './pages/aboutchild/Quality';
import ProductOne from './pages/productchild/product_one';
import ProductTwo from './pages/productchild/product_two';
import IndustriesOne from './pages/industrieschild/IndustriesOne';
import IndustriesTwo from './pages/industrieschild/IndustriesTwo';
import Industries from './pages/Industries';
import Consulting from './pages/serviceschild/Consulting';
import CustomSolutions from './pages/serviceschild/CustomSolutions';
import TechnicalSupport from './pages/serviceschild/TechnicalSupport';
import LifeTml from './pages/careerchild/LifeTml';
import OpenPositions from './pages/careerchild/OpenPositions';
import SingleJob from './pages/careerchild/SingleJob';
import MediaPage from './pages/resourceschild/MediaPage';
import MediaPageVideo from './pages/resourceschild/MediaPageVideo';
import NewsComp from './pages/resourceschild/NewsComp';
import Events from './pages/resourceschild/Events';
import Blogs from './pages/resourceschild/Blogs';
import Faq from './pages/resourceschild/Faq';
import CaseStudies from './pages/resourceschild/Casestudies';
import Whitepaper from './pages/resourceschild/Whitepaper';
import Brochures from './pages/resourceschild/Brochures';
import Footernew from './components/global/Footernew';
import OurClients from './pages/aboutchild/OurClients';
// import History from './pages/aboutchild/History';
import VisionandMission from './pages/aboutchild/VisionandMission';
import Organisation from './pages/aboutchild/Organisation';
import SingleWhite from './pages/singlepages/SingleWhite';

import SingleProduct from './pages/productchild/singleproduct';
import SingleEvent from './pages/singlepages/SingleEvent';
// import Manufacturing from './pages/aboutchild/Manufacturing';
import CompanyCulture from './pages/aboutchild/CompanyCulture';
import Esr from './pages/aboutchild/Esr';
import BlogSingle from './pages/singlepages/BlogSingle';
import CaseStudy from './pages/singlepages/CaseStudy';
import NewsSingle from './pages/singlepages/NewsSingle';
import CoreValues from './pages/aboutchild/CoreValues';
import CategoryArchive from './pages/singlepages/CategoryArchive';
import { ScrollToTop } from './components/global/ScrollToTop';
import {Navigation2} from './components/global/Navigation2';

import { RibbonPage } from './pages/singlepages/RibbonPage';

import {NewSingle} from './pages/newisingle';
import { CAnewsRoom } from './pages/resourceschild/CAnewsRoom';
import { MCnewsRoom } from './pages/resourceschild/MCnewsRoom';

function App() {
  return (
    <BrowserRouter>
      <>
      <ScrollToTop />
        <Navigation2/>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/home" element={<HomeComponent />} />
          <Route path="/about" element={<AboutComponent />} />
          <Route path="/our-offerings" element={<OurOffering/>} />
          <Route path="/industries" element={<Industries/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/career/update-resume" element={<Career />} />
          <Route path="/sustainability" element={<Sustainability/>} />
          <Route path="/insights" element={<Resources/>} />
          <Route path="/contact-us" element={<Contact/>} />
          
          <Route path="/single-product/:slug" element={<SingleProduct/>} />
          <Route path="/blog/:slug" element={<BlogSingle/>} />
          <Route path="/case-study/:slug" element={<CaseStudy/>} />
          <Route path="/news/:slug" element={<NewsSingle/>} />
          <Route path="/product/category/:product_category" element={<CategoryArchive />} />
          {/* About Child */}
          <Route path="/core-values" element={<CoreValues/>} />
          <Route path="/company-overview" element={<ConpmanyOverview/>} />
          {/* <Route path="/values" element={<Values/>} /> */}
          <Route path="/our-team" element={<Leadership/>} />
          <Route path="/our-clients" element={<OurClients/>} />
          <Route path="/organisation" element={<Organisation/>} />
          <Route path="/mission-statement" element={<VisionandMission/>} />
          {/* <Route path="/manufacturing-facilities" element={<Manufacturing/>} /> */}
          <Route path="/company-culture" element={<CompanyCulture/>} />
          <Route path="/environmental-and-social-responsibility" element={<Esr/>} />
          {/* <Route path="/" element={<Manufacturing/>} /> */}
          
          {/* <Route path="/operational-team" element={<Operational/>} /> */}
          {/* <Route path="/awards-and-achievements" element={<Awards/>} /> */}
          <Route path="/quality-assurance" element={<Quality/>} />
          {/*<Route path="/r-and-d" element={<Research/>} /> */}


          <Route path="/product-one" element={<ProductOne/>} />
          <Route path="/product-two" element={<ProductTwo/>} />

          <Route path="/industry-one" element={<IndustriesOne/>} />
          <Route path="/industry-two" element={<IndustriesTwo/>} />

          <Route path="/services/consulting" element={<Consulting/>} />
          <Route path="/services/custom-solutions" element={<CustomSolutions/>} />
          <Route path="/services/technical-support" element={<TechnicalSupport/>} />

          <Route path="/career/life-tml" element={<LifeTml/>} />
          <Route path="/career/open-positions" element={<OpenPositions/>} />
          <Route path="/career/open-positions/:slug" element={<SingleJob/>} />
          {/* <Route path="/career/open-positions/:slug" element={<SingleJob/>}/> */}


           {/* <Route path="/whitepaper/:slug" element={<SingleWhite/>} />  */}
           {/* <Route path="/event/:slug" element={<SingleEvent/>}/> */}

          <Route path="/media" element={<MediaPage/>} />
          <Route path="/media-video" element={<MediaPageVideo/>}/>
          {/* <Route path="/events" element={<Events/>} /> */}
          <Route path="/blogs" element={<Blogs/>} />
          {/* <Route path="/faq" element={<Faq/>} /> */}
          <Route path="/case-studies" element={<CaseStudies/>} />
          {/* <Route path="/whitepaper" element={<Whitepaper/>} /> */}
          <Route path="/downloads" element={<Brochures/>} />

          <Route path='/ribbon-page' element={<RibbonPage/>}/>

          <Route path='/newpro/:id' element={<NewSingle/>}/>

          <Route path="/newsroom/news-events" element={<NewsComp/>} />
          <Route path="/newsroom/company-announcement" element={<CAnewsRoom/>} />
          <Route path="/newsroom/media-coverage" element={<MCnewsRoom/>} />


        </Routes>
        <Footernew/>
      </>
    </BrowserRouter>
  );
}

export default App;
