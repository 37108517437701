import React, { useRef, useState,useEffect } from "react";
import InternalBanner from "../components/global/InternalBanner";
import { Form, Button, InputGroup, FormControl, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
const Career = () => {
      const [jobsall, setJobsAll] = useState([]);
      const apiUrl = process.env.REACT_APP_API_URL;

      const jobs = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/api/admin/jobs`);
            // console.log(response.data);
            setJobsAll(response.data.jobs);
            // console.log(jobsall);
            // console.log(jobsall);
        } catch (error) {
          console.error(error);
        }
      };

     

      useEffect(() => {
        jobs();
        
    
      }, []);

      useEffect(() => {
        document.title = "Careers | Tega Mcnally";
      }, []);
    const bannerData = {
        imgurl: '/images/update_resem_banner.jpg',
        // title: "Update Resume",
  
    };
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            toast.error('Please select a file to upload');
            return;
        }

            // Create a new FormData object
            const formData = new FormData();

            // Append form fields to the FormData object
            formData.append('name', event.target.name.value);
            formData.append('email', event.target.email.value);
            formData.append('job', event.target.job.value);
            formData.append('experience', event.target.experience.value);
            formData.append('tell_us_more', event.target.tell_us_more.value);

            // Append the file to the FormData object
            formData.append('cv', event.target.cv_file.files[0]);


        try {
            await axios.post('https://tega.codtrees.com/api/careers', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            event.target.name.value = null;
            event.target.email.value = null;
            event.target.job.value = null;
            event.target.experience.value = null;
            event.target.tell_us_more.value = null;
            setSelectedFile(null);
            toast.success('File uploaded successfully');
            setSelectedFile(null); // Clear file input
        } catch (error) {
            toast.error('File upload failed');
            console.log(error);
        }
        
    };


    return (
        <>
          <Helmet>
    <title>Update Resume | Tega Mcnally</title>
    <meta name="title" content="Update Resume" />
    <meta name="description" content="Update Resume" />
</Helmet>
            <ToastContainer className="toastify_modal"/> 
            <InternalBanner {...bannerData} />
            <Container>
                <div className="page-content">
                    <div className="text-center">
                        <h2>Apply Online</h2>
                       
                    </div>
                    <div className="colspac"></div>
                    <Row className="shadow">
                        <Col md={6} className="carcont">
                            {/* <img src="images/career.jpg" alt="career" className="img-fluid"/> */}
                        </Col>

                        <Col md={6} className="pzero">
                            <Form
                                onSubmit={handleSubmit}
                                className="p-4 bg-white careerform"
                                encType="multipart/form-data"
                            >
                                <Form.Group className="mb-4">
                                    <h3 className=" text-center">Fill the form below to apply</h3>
                                    <InputGroup>
                                        <FormControl
                                            required
                                            name="name"
                                            type="text"
                                            placeholder="Your name"
                                            maxlength="50"
                                        />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <InputGroup>
                                        <FormControl
                                            required
                                            name="email"
                                            type="email"
                                            placeholder="Email address"
                                            maxlength="50"
                                        />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                <Form.Label>Select Job</Form.Label>
                                <InputGroup>
                                    <Form.Select 
                                        name="job"
                                        required
                                    >
                                        <option value="">Select Job</option>
                                        {jobsall.map((job, index) => (
                                            <option key={index} value={job.job_title}>{job.job_title}</option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Years of Experience</Form.Label>

                                    <Form.Select required name="experience">
                                        <option>Less than a year</option>
                                        <option>1 - 2 years</option>
                                        <option>2 - 4 years</option>
                                        <option>4 - 7 years</option>
                                        <option>7 - 10 years</option>
                                        <option>10+ years</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <FormControl
                                        name="tell_us_more"
                                        as="textarea"
                                        rows="3"
                                        placeholder="Tell us more about yourself"
                                        maxlength="50"
                                    />
                                </Form.Group>
                                <Form.Label>Your CV</Form.Label>
                                <InputGroup>

                                    <FormControl
                                        ref={fileInputRef}
                                        required
                                        name="cv_file"
                                        type="file"
                                        style={{ display: 'none' }} // Hide the actual input
                                        onChange={handleFileChange} // Handle file change
                                    />
                                    <InputGroup.Text
                                        as={Button}
                                        className="btn-c"
                                        onClick={handleChooseFileClick}
                                    >
                                        Choose File
                                    </InputGroup.Text>
                                    {selectedFile && <span className="ml-2">{selectedFile.name}</span>} {/* Display selected file name */}
                                </InputGroup>

                                <div className="mb-3 mt-4 text-center">
                                    <Button type="submit" variant="primary" className="btn-c">
                                        Apply Now &nbsp; <i class="fas fa-long-arrow-alt-right"></i>
                                    </Button>
                                </div>


                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default Career;
