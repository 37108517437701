import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaTwitter, FaLinkedin, FaFacebook, FaEnvelope } from "react-icons/fa"; // Import social icons
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import img1 from '../../images/1.jpg'
import img2 from '../../images/2.0.jpg'
import img3 from '../../images/3.jpg'
import img4 from '../../images/4.jpg'
import img5 from '../../images/6.jpg'

const MeetPioneers = () => {
  const [pioneer, setPioneers] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const pioneers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/addpioneers`);
      setPioneers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [selectTeam, setSelectTeam] = useState('Madan')

  useEffect(() => {
    pioneers();
  }, []);

  return (
    <div className="meepio">
      <Container>
        {/* <h2 className="bbefore">
          <span>Our Team</span>
        </h2> */}

        <Row>
          <Col md={9} className="team-information">
            <Row key={pioneers.id} className={`mb-4 teamclass`}>
            <h2 className="bbefore">
                  <span>Madan Mohanka</span>
                </h2>
              <Col md="4">
                <img
                  src="images/MadanMohanka.jpg"
                  className="img-fluid"
                  alt="team"
                  style={{ height: "300px", objectFit: "cover" }}
                />
              </Col>
              <Col md="8" className="justify-center-team">
                <p className="bold-text">
                  <strong>Designation: </strong>Director
                </p>
                <p>
                  Mr. Madan Mohan Mohanka holds a Bachelor's Degree in Science
                  (Engineering) from Ranchi University and a Post Graduate
                  Diploma in Business Administration from Indian Institute of
                  Management, Ahmedabad. He has been associated with the Company
                  since its incorporation.
                </p>
              </Col>
            </Row>

            <Row key={pioneers.id} className={`mb-4 teamclass`}>
            <h2 className="bbefore">
                  <span>Mehul Mohanka</span>
                </h2>
              <Col md="4">
                <img
                  src="images/MDHorizontalImage.JPG"
                  className="img-fluid"
                  alt="team"
                  style={{ height: "320px", objectFit: "cover" }}
                />
              </Col>
              <Col md="8" className="justify-center-team">
                <p className="bold-text">
                  <strong>Designation: </strong>Director
                </p>
                <p>
                  Mr. Mehul Mohanka holds a Bachelor's Degree in Commerce from
                  University of Calcutta and a Master's Degree in Business
                  Administration from the University of Pittsburgh.
                  Additionally, he completed an Advanced Management Program at
                  Harvard Business School. He is associated with the Indian
                  Chamber of Commerce as President; and the Mining and
                  Construction Equipment Division of Confederation of Indian
                  Industry as the Chairman. He is also the Co-chair of the
                  National Committee on Mining of the Confederation of Indian
                  Industry; and the Chairman of the West Bengal State Council of
                  the Confederation of Indian Industry. He has been an integral
                  part of the organisation for almost two decades and has been
                  instrumental in diverse functions of the brand, such as sales
                  and operations.
                </p>
              </Col>
            </Row>

              {/* new */}
              
            <Row key={pioneers.id} className={`mb-4 teamclass`}>
            <h2 className="bbefore">
                  <span>Syed Yaver Imam</span>
                </h2>
              <Col md="4">
                <img
                  src="images/DSC_8495.JPG"
                  className="img-fluid"
                  alt="team"
                  style={{ height: "320px", objectFit: "cover" }}
                />
              </Col>
              <Col md="8" className="justify-center-team">
                <p className="bold-text">
                  <strong>Designation: </strong>CEO
                </p>
                <p>
                He holds a Bachelor's degree in Civil Engineering from Jadavpur University and completed a short-term course in mineral processing from Indian School of Mines, Dhanbad. He has completed advanced management programmes conducted by Stanford University & National University of Singapore. Additionally he has completed senior management programmes conducted by  IIM Ahmedabad, IIM Lucknow and  XLRI Jamshedpur .
                 <br />He has been integral part of the  Tega group  for the past four decades and  has been part of  the strategy formulation, sales , operation and M&A.  He has been part of the executive team to run the global business of the group for the past two decades.

                </p>
              </Col>
            </Row>

            <div className="bod">
              <h2 className="bbefore">
                <span>Board of Directors</span>
              </h2>

              <Row>
                <Col md={6} >
                  <div className="member-card">
                    <img src={img1} alt="" />
                    <div className="bod-name">Mr. Madan Mohan Mohanka</div>
                    <p>- Director</p>
                  </div>  
                </Col>
                <Col md={6}>
                  <div className="member-card">
                    <img src={img2} alt="" />
                    <div className="bod-name">Mr. Mehul Mohanka</div>
                    <p>- Director</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="member-card slim-card">
                    <img src={img3} alt="" />
                    <div className="bod-name">Mr. Manoj Kumar Sinha</div>
                    <p>- Wholetime Director</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="member-card slim-card">
                    <img src={img4} alt="" />
                    <div className="bod-name">Mr. Sharad Khaitan</div>
                    <p>- Additional Director (Category : NED)</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="member-card slim-card">
                    <img src={img5} alt="" />
                    <div className="bod-name">Mr. Ashwani Maheshwari</div>
                    <p>– Independent Director</p>
                  </div>
                </Col>
              </Row>

            </div>

          </Col>
          <Col md={3} className="flter_co">
            <div className="flter_co-inner">
              <ul className="filter_ul">
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/Thumb_about.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/about"} style={{ color: "white" }}>
                          About us
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>

                {/* <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/thumb_team.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/company-overview"} style={{ color: "white" }}>
                        Company Overview
                        </Link>

                      </h5>
                    </div>
                  </div>
               
                </li> */}

                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/manu_fac.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/manufacturing-facilities"} style={{ color: "white" }}>
                          Manufacturing Facilities
                        </Link>

                      </h5>
                    </div>
                  </div>
                  </li>
                
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/thumb_client.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center" >
                        <Link to={"/our-clients"} style={{ color: "white" }}>
                          Clientele
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MeetPioneers;
