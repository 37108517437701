import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from 'axios';

const FocusIndustry = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [industries, setIndustry] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(1);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const industry = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/addindustry`);
      setIndustry(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    industry();
  }, []);

  return (
    <div>
      <Container className="iof">
        <h3 className="bbefore">
          <span>Industries</span>
        </h3>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={50}
          loop={true}
          grabCursor={false}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          modules={[Navigation, Autoplay, Pagination]}
          className="mySwiper mt-4"
          pagination={{ clickable: true,
        dynamicBullets: true,}}
        >
          {industries.map((industry, index) => (
            <SwiperSlide key={index}>
              <Card>
                <Card.Img className="shadow focus-img" variant="top" src={`${apiUrl}/public/Assets/Image/industry/${industry.industry_image}`} />
                <Card.Body className="text-center">
                  <h4>{industry.industry_name}</h4>
                </Card.Body>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
}

export default FocusIndustry;