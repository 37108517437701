import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const CaseStudies = () => {
  const bannerData = {
    imgurl: "/images/industries.jpg",
    title: "Case Studies",
    description: "This is Case Studies page",
  };
  const blogs = [
    {
      id: 1,
      title: "This is a demo",
      content:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>",
      img: "images/industries.jpg",
      url: "#",
    },
    {
      id: 2,
      title: "This is a demo two",
      content:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>",
      img: "images/industries.jpg",
      url: "#",
    },
    {
      id: 3,
      title: "This is a demo three",
      content:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>",
      img: "images/industries.jpg",
      url: "#",
    },
    {
      id: 4,
      title: "This is a demo four",
      content:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>",
      img: "images/industries.jpg",
      url: "#",
    },
  ];
  return (
    <>
      <InternalBanner {...bannerData} />
      <Container>
        <div className="page-content">
          <Row>
            {blogs.map((blog, index) => (
              <Col key={index} md={4} className="mb-4">
                <Card className="newcard">
                  <Card.Body>
                    <Card.Img variant="top" src={blog.img} />
                    <div className="newscont">
                      <h2 className="mt-2 mb-2">{blog.title}</h2>

                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: blog.content }}
                      ></div>
                    </div>
                    <div className="text-right float-end">
                      <Link
                        to={blog.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="blogbut"
                      >
                        Read More
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default CaseStudies;
