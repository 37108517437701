import React, { useEffect, useState } from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../css/meida_image.css";
import { useLocation } from "react-router-dom";
import { act } from "react";
import { Helmet } from "react-helmet";
const MediaPageVideo = () => {
  const [selectedCategory, setSelectedCategory] = useState("product-showcase");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const bannerData = {
    imgurl: "/images/industries.jpg",
    // title: "Videos",
  };

  const location = useLocation()
  const {cate_type=""} = location.state || '';

  const fetchSubCategories = async (category) => {
    try {
      const response = await fetch(`https://tega.codtrees.com/api/admin/videocategory/${category}`);
      if (!response.ok) {
        throw new Error("Failed to fetch subcategories");
      }
      const data = await response.json();
      const uniqueSubcategories = [...new Set(data.video.map((item) => item.video_title))];
      setSubCategories(uniqueSubcategories);
      // if (uniqueSubcategories.length > 0) {
      //   setSelectedSubCategory(uniqueSubcategories[0]);
      //   setFilteredVideos(data.video.filter((video) => video.video_title === uniqueSubcategories[0]));
      // }
      const activeCategory = cate_type.length > 2 ? cate_type : uniqueSubcategories[0]
      console.log(activeCategory)
      setSelectedSubCategory(activeCategory);
      setFilteredVideos(data.video.filter((video) => video.video_title === activeCategory));
    } catch (error) {
      console.error("Error fetching subcategories:", error.message);
      setSubCategories([]);
    }
  };

  const fetchVideosByCategory = async (category) => {
    try {
      const response = await fetch(`https://tega.codtrees.com/api/admin/videocategory/${category}`);
      if (!response.ok) {
        throw new Error("Failed to fetch videos");
      }
      const data = await response.json();
      setAllVideos(data.video);
      if (selectedSubCategory) {
        setFilteredVideos(data.video.filter((video) => video.video_title === selectedSubCategory));
      }
    } catch (error) {
      console.error("Error fetching videos:", error.message);
      setAllVideos([]);
      setFilteredVideos([]);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchSubCategories(category);
    fetchVideosByCategory(category);
  };

  const handleSubCategoryClick = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setFilteredVideos(allVideos.filter((video) => video.video_title === subCategory));
  };

  useEffect(() => {
    fetchSubCategories("product-showcase");
    fetchVideosByCategory("product-showcase");
  }, []);

  // useEffect(() => {
  //   if (subCategories.length > 0) {
  //     setSelectedSubCategory(subCategories[0]);
  //     setFilteredVideos(allVideos.filter((video) => video.video_title === subCategories[0]));
  //   }
  // }, [subCategories]);

  return (
    <>
    <Helmet>
    <title>Videos | Tega Mcnally</title>
    <meta name="title" content="Videos | Tega Mcnally" />
    <meta name="description" content="Videos" />
</Helmet>
      <InternalBanner {...bannerData} />
      <Container id="portfolio" className="media_page">
        <Row>
          {/* Left sidebar for category selection */}
          <Col md={3}>
            <div className="filter-container">
              <ul className="pdflist shadow">
                <div className="tile__title">Categories</div>
                {[
                  "product-showcase",
                  "events-trade-shows",
                  "corporate-images",
                  "corporate-social-responsibility",
                  "employee-spotlight",
                  "training-and-education",
                  "company-awards",
                  "media-coverage",
                  "certifications",
                  "testimonials",
                ].map((category, index) => (
                  <li key={index}>
                    <Button
                      className={`btn filter-button ${selectedCategory === category && "active"}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <i className="fa fa-angle-double-right" aria-hidden="true"></i> {category.replace(/-/g, ' ')}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          {/* Main content area for videos */}
          <Col md={6}>
            <Row>
              {filteredVideos.map((video, index) => (
                <Col key={index} md={6}>
                  <div className="video-container">
                    <iframe
                      width="300"
                      height="300"
                      src={video.video_link}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          {/* Right sidebar for subcategory selection */}
          <Col md={3}>
            <div className="filter-container">
              <ul className="pdflist">
                <div className="tile__title">Subcategories</div>
                {subCategories.map((subCategory, index) => (
                  <li key={index}>
                    <Button
                      className={`btn sub-category-button filter-button ${selectedSubCategory === subCategory && "active"}`}
                      onClick={() => handleSubCategoryClick(subCategory)}
                    >
                      <i className="fa fa-angle-double-right" aria-hidden="true"></i> {subCategory}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MediaPageVideo;
