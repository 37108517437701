import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";

const CoreValues=()=>{
    const bannerData={
        imgurl:'images/industries.jpg',
        title:"Core Values",
        // description:"This is Mission and Vision  page",
    };
    return(
        <div>
                <InternalBanner{...bannerData}/>
    <div className="page-content">
    <Container>
      
      <p>Our core values at TMML are deeply ingrained in our dedication to excellence, innovation, and unwavering customer satisfaction. We prioritize quality excellence, ensuring that our products and services consistently meet the highest standards, as evidenced by our ISO 9001 - 2015 certification across all our manufacturing units. Innovation is at the heart of our operations, as we continuously absorb technology through strategic alliances and maintain a robust R&D and design & development team. This commitment allows us to stay ahead of the curve, delivering cutting-edge solutions to meet the evolving needs of our customers.</p>
            
       <p>Our customer-centric approach is central to everything we do. We strategically place marketing and branch offices across the country to provide total customer support, offering customized and cost-effective solutions tailored to meet the unique requirements of each client. Additionally, ethical and responsible business practices are fundamental to our operations. We adhere to stringent quality standards, including ISO 14001-2015 accreditation, reflecting our commitment to environmental sustainability and ethical conduct.</p>

       <p>Beyond our domestic endeavours, we have expanded our reach globally, with our equipment installed and operational in various countries worldwide. This global presence underscores our ability to collaborate effectively with international partners, enhancing our capabilities and extending our market reach. In essence, our core values of excellence, innovation, customer focus, ethical conduct, and global collaboration define our identity and propel our continued success in the industry.</p>     
        </Container>
        
        </div>
        </div>
        );
}

export default CoreValues;