import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container } from "react-bootstrap";
import MeetPioneers from "../../components/homepage/MeetPioneers";
import { Helmet } from "react-helmet";
const Leadership = () => {

    const bannerData={
        imgurl:'images/our_team_banner.jpg',
        // title:"Our Team",
       
    };

    return(
        <>

<Helmet>
<title>Our Team | Tega Mcnally</title>
  <meta name="title" content="Our Team  | Tega Mcnally"/>
<meta name="description" content="Meet Our Leadership Team"/>

</Helmet>
        <InternalBanner{...bannerData}/>
        <Container>
        <div className="page-content">
            <MeetPioneers/>
        </div>
        </Container>
        </>
    );
}   

export default Leadership;