import React from "react";
import InternalBanner from "../components/global/InternalBanner";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../css/services.css";
import { useRef, useState,useEffect } from "react";


const Services = () => {

  useEffect(() => {
    document.title = "Services | Tega Mcnally";
  }, []);


    const bannerData={
        imgurl:'images/industries.jpg',
        title:"Our Services",
        description:"This is  Services page",
    };

    const servicesdet = [
        {
          id: 1,
          title: "Consulting",
          content:
            "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>",
          img: "images/industries.jpg",
          url: "/services/consulting",
        },
        {
            id: 2,
            title: "Custom Solutions",
            content:
              "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>",
            img: "images/industries.jpg",
            url: "/services/custom-solutions",
          },
          {
            id: 1,
            title: "Technical Support",
            content:
              "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>",
            img: "images/industries.jpg",
            url: "/services/technical-support",
          },
      ];
    

    return(
        <>
        <InternalBanner{...bannerData}/>
        <Container>
        <div className="page-content">
        <Row>
        {servicesdet.map((sdet, index) => (

            <Col lg={4} md={6}>
            <div className="content-box2 m-b30">
  <div className="dz-info">
    <h3 className="title">{sdet.title}</h3>
    <p dangerouslySetInnerHTML={{ __html: sdet.content }}></p>
  </div>
  <div className="dz-media m-b30">
    <img src={sdet.img} className="img-fluid" alt />
  </div>
  <div className="dz-bottom">
  <Link to={sdet.url} className="btn-link">
    READ MORE
    <i className="fas fa-arrow-right" />
  </Link>  </div>
</div>
            </Col>
               ))}
        </Row>
        </div>
        </Container>
        </>
    );
}   

export default Services;