import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';


const apiUrl = process.env.REACT_APP_API_URL;

function NewsSingle(props) {
    const [news, setNews] = useState([]);
    const [newsall, setNewss] = useState([]);
    const { slug } = useParams();

    const newstotal = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/api/admin/addnews`);
            setNewss(response.data);
            console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        newstotal();
      }, []);
    useEffect(() => {
        let isMounted = true;

        axios.get(`${apiUrl}/api/admin/news/${slug}`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setNews(res.data.news);
                    console.log(res.data);
                }
            }
        })
    }, []);
    const bannerData = {
        imgurl: '../images/industries.jpg',
        title: news.news_title,
        // description: "This is event page",
    };

    function truncateDescription(description) {
        // Decode HTML entities
        description = decodeURIComponent(description);
      
        // Split the description into words
        const words = description.split(' ');
      
        // Only take the first 30 words
        const truncatedWords = words.slice(0, 10);
      
        // Join the words back into a string
        return truncatedWords.join(' ');
      }
    return (
        <>
            <InternalBanner{...bannerData} />
            <Container>
            <div className="page-content">
                <Row className="">
                    <Col md={8}>
                <img src={`${apiUrl}/public/Assets/Image/news/${news.news_image}`} className="img-fluid blogc" alt={news.blog_title} />
                <div className="blog-content mt-4" dangerouslySetInnerHTML={{ __html:news.news_description }}></div>
                </Col>
                <Col md={4}>
                    <h3 className="bbefore"><span>Recent Case Studies</span></h3>
                    {newsall.map((blog, index) => (
                        <div>
                        <Row className="mb-4 blogcatp">
                            <Col md={3}>
                            <img src={`${apiUrl}/public/Assets/Image/news/${blog.news_image}`} className="img-fluid" alt={blog.news_title} />
                            </Col>
                            <Col md={9}>
                               <Link to={`/news/${blog.slug}`} target="_blank"> <h3><small>{blog.news_title}</small></h3></Link>
                                {/* <di dangerouslySetInnerHTML={{ __html:truncateDescription(blog.blog_description) }}></di> */}
                             </Col>
                            
                        </Row>
                        
                        <hr/>
                        </div>
                        
                    ))} 
                    
                </Col>
                </Row>
                </div>
            </Container>
        </>
    );

}

export default NewsSingle;