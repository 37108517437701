import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import '../../css/newpro.css';
import "../css/newpro.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import DOMPurify from "dompurify";
import axios from "axios";
import { Helmet } from "react-helmet";
export const NewSingle = () => {
  const [activeIndex, setActiveIndex] = useState("0");
  const [slidesPerView, setSlidesPerView] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(1);
      }
    };




    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleAccordion = (index) => {
    setActiveIndex(index.toString());
  };
  const product = {};
  const sanitizedHTML = DOMPurify.sanitize(product.product_description);

  const [productItems, setProductItems] = useState([])
  useEffect(() => {
    document.title = `${productItems.product_name} | Tega Mcnally`;
  }, [productItems]);

  const { id } = useParams();

  useEffect(()=>{
    axios.get('https://tega.codtrees.com/api/singleproduct', { params: { id: id } })
    .then(res=>{
      setProductItems(res.data.Product)
    }).catch(err=>{
      console.log(err)
    })
  },[id])

  return (
    
    <div className="newSingle">
      <Helmet>
    <title>{`${productItems.product_name} | Tega Mcnally`}</title>
    <meta name="title" content={`${productItems.product_name} | Tega Mcnally`} />
    <meta name="description" content={`${productItems.product_name}`} />
</Helmet>
      <div className="bgPro">
        {/* <h1 className="title">{productItems.product_name}</h1> */}
        <img src={`https://tega.codtrees.com/public/storage/product/${productItems.product_image}`} alt="" />
      </div>
      <Row className="stikcy-top">
        <div className="mainnavpro">
          <Container>
            <ul className="d-flex justify-content-between prolistnew">
              <li>
                <a href="#overview">Overview</a>
              </li>
              <li>
                <a href="#what-we-offer">What We Offer</a>
              </li>
              <li>
                <a href="#key-benefits">Key Benefits</a>
              </li>
              <li>
                <a href="#product-features">Product Features</a>
              </li>
              <li>
                <a href="#service">Service</a>
              </li>
              <li>
                <a href="#service">Spares</a>
              </li>
              <li>
                <a href="#downloads">Downloads</a>
              </li>
            </ul>
          </Container>
        </div>
      </Row>
      <Container>
        <Row className="my-4 ">
          <Col md={9} className="new-prod-container">
            {/* <div className="text-center scroll-snap" id="overview">
              {productItems.product_description}
            </div> */}
            <div
                className="text-center scroll-snap text-justify" id="overview"
                dangerouslySetInnerHTML={{ __html: productItems.product_description }}
              ></div>
            <div className="colspac " ></div>
            <div className="text-center scroll-snap" id="what-we-offer">
              <h2>What we Offer</h2>
            </div>
            <div className="colspachalf"></div>
            <Row className="featimg">
              {
                productItems.variation_offer_description && productItems.variation_offer_description.map((item)=>(
                  <Col md={2} key={item.index}>
                    {/* <img src={`https://tega.codtrees.com/public/storage/product/${item.gallery_path}`} alt="" /> */}
                    <h6>{item.description}</h6>
                  </Col>
                ))
              }
            </Row>
            <div className="colspac" ></div>
            <div className="text-center scroll-snap" id="key-benefits">
              <h2>Key Benefits</h2>
            </div>
            <div className="colspachalf"></div>
            <Accordion defaultActiveKey={activeIndex} className="mt-4 proacco">
              {productItems.variation_key_benefits_question && productItems.variation_key_benefits_question.map((items, index) => (
                <Accordion.Item
                  key={index}
                  eventKey={index.toString()}
                  className={activeIndex === index.toString() ? "act" : "inact"}
                  onClick={() => toggleAccordion(index.toString())}
                >
                  <Accordion.Header>
                    <span className="acno">{index + 1}</span>
                    {items.question}
                  </Accordion.Header>
                  <Accordion.Body>{items.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <div className="colspac"></div>
            <div className="text-center scroll-snap" id="product-features">
              <h2>Product Features</h2>
            </div>
            <Row xs={4} md={2} lg={4} className="g-4"  >
              <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={50}
                loop={true}
                grabCursor={false}
                modules={[Navigation, Autoplay, Pagination]}
                navigation={{
                  clickable: true,
                }}
                className="mySwiper mt-4 "
              >
                {
                  productItems.variation_product_feature_title && productItems.variation_product_feature_title.map((item, index)=>(
                    <SwiperSlide key={index}>
                      <Col>
                        <Card className="profeat">
                          <Card.Img
                            className="offering-img"
                            variant="top"
                            src={`https://tega.codtrees.com/public/storage/product/${item.gallery_path}`}
                          />
                          <Card.Body className="product-featbody">
                            <p>
                              <strong>{item.title}</strong>
                            </p>
                            <p>{item.description}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </Row>

            <div className="services-contact scroll-snap" id="service">
                <div className="contact-1">
                    <p>Services Contact +91 9073122222<br/>
                    Email: info@tegaminerals.com</p>
                </div>
                <div className="contact-2">
                    <p>Spare Contact +91 9073122222<br/>
                     Email: info@tegaminerals.com
                    </p>
                   
                </div>
            </div>

            <div className="prod-downloads scroll-snap" id="downloads">
                <div className="text-center">
                    <h2>Downloads</h2>
                </div>

                <table>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Action</th>
                    </tr>
                    <tbody>
                      {
                        productItems.variation_pdf_title && productItems.variation_pdf_title.map((item, index)=>(
                        <tr>
                          <td><i className="fa fa-file-pdf" aria-hidden="true"></i></td>
                          <td>{item.pdf_title}</td>
                          <td>2MB</td>
                          <td className="download_text"><a target="_black" href={`https://tega.codtrees.com/public/storage/product/${item.pdf_path}`} download>Download</a></td>
                        </tr>
                        ))
                      }
                    </tbody>
                </table>
            </div>
          </Col>

          <Col md={1} className="enquire-section">
              <Link to={'/contact-us'} className="enquire-btn-btn">
                   Enquiry now
              </Link>
          </Col>

          <Col md={2} className="prod-sidebar-sticky">
            <div className="new-prod-sidebar">
              <Link to={`/blogs`} state={{cate_type:productItems.product_link}}>
                <div className="sidebar-content">
                  <img src="/images/product_about.jpg" alt="" />
                  <p>blogs</p>
                </div>
              </Link>
              <Link to={`/blogs`} state={{cate_type:productItems.product_link}}>
                <div className="sidebar-content">
                  <img src="/images/product_articles.jpg" alt="" />
                  <p>articles</p>
                </div>
              </Link>
              <Link to={'/media'} state={{cate_type:productItems.product_link}}>
                <div className="sidebar-content">
                  <img src="/images/product_image.jpg" alt="" />
                  <p>Images</p>
                </div>
              </Link>
              <Link to={'/media-video'} state={{cate_type:productItems.product_link}}>
                <div className="sidebar-content">
                  <img src="/images/product_videos.jpg" alt="" />
                  <p>Videos</p>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
        <div className="prod-contact-us">
          <div className="">
            <h2>Contact Us</h2>
          </div>
          <form action="">
            <div className="prod-input-divide">
              <input type="text" placeholder="First Name"/>

              <input type="text" placeholder="Last Name"/>
            </div>
            <div className="prod-input-divide">
              <input type="number" placeholder="Phone Number"/>

              <input type="email" placeholder="Email"/>
            </div>

            <input type="text" placeholder="Company"/>

            <textarea name="" id="" placeholder="What is your message"></textarea>

            
            <button type="submit" className="btn-c">Submit</button>
            
          </form>
        </div>
      </Container>
    </div>
  );
};
