import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Parallax } from "swiper/modules";
import { useSpring, animated } from "react-spring";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/parallax";
import "swiper/css/effect-coverflow";
import { Row } from "react-bootstrap";
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const CarouselComponent = () => {
  const [slides, setSlides] = useState([]);
  const swiperRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const sliders = async () => {
    try {
      const response = await axios.get(`https://tega.codtrees.com/api/admin/addslider`);
      setSlides(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    sliders();
  }, []);

  const handleSlideChange = () => {
    const swiperInstance = swiperRef.current.swiper;
    setActiveSlideIndex(swiperInstance.activeIndex);
  };

  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateX(100%)' },
    to: async (next) => {
      await next({ opacity: 0, transform: 'translateX(100%)' });
      await next({ opacity: 0, transform: 'translateX(80%)' });
      await next({ opacity: 0, transform: 'translateX(60%)' });
      await next({ opacity: 0, transform: 'translateX(40%)' });
      await next({ opacity: 0, transform: 'translateX(20%)' });
      await next({ opacity: 1, transform: 'translateX(0%)' });
    },
    reset: true,
    config: { duration: 250 }, // Adjust duration value for each step
  });
  

  return (
    <div className="swiper-container hometop">
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay, Parallax]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        slidesPerView={1}
        autoplay={{ delay: 9000, disableOnInteraction: false }}
        speed={800}
        parallax={false}
        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
        }}
        spaceBetween={0}
        onSlideChange={handleSlideChange}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={slide.id}
            style={{
              backgroundImage: `
                linear-gradient(
                  rgba(0, 0, 0, 0.5), 
                  rgba(0, 0, 0, 0.5)
                ),
                url('${apiUrl}/public/Assets/Image/slider/${slide.slider_image}')
              `,}}
          >
            <animated.div
              key={activeSlideIndex} // Unique key to reset animation
              className={`slc ${activeSlideIndex === index ? "visible" : ""}`}
              style={springProps}
            >
              <div className="bannercontainer">
                <Row>
                  <div className="col-lg-12">
                    <h1>{slide.slider_title}</h1>
                  </div>
                </Row>
              </div>
            </animated.div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev swbut"></div>
        <div className="swiper-button-next swbut"></div>
      </Swiper>
    </div>
  );
};

export default CarouselComponent;
