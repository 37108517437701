import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import { Helmet } from "react-helmet";


function SingleJob(props) {
    
    const [job, setJob] = useState([]);
    const { slug } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        let isMounted = true;

        axios.get(`${apiUrl}/api/admin/job/${slug}`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setJob(res.data.job);
                }
            }
        })
    }, []);
    const bannerData = {
        imgurl: '/../images/industries.jpg',
        // title: job.job_title,
        // description: "This is Career page",
    };
    return(
        <>
        <Helmet>
    <title>{`${job.job_title} | Tega Mcnally`}</title>
    <meta name="title" content={`${job.job_title} | Tega Mcnally`} />
    <meta name="description" content={`${job.job_title}`} />
</Helmet>
         <InternalBanner{...bannerData} />
         <div className="page-content">

         <Container>
            <div className="jodesc">
            <h2 className="bbefore"><span>{job.job_title}</span></h2>
            <p><strong>Location:</strong> {job.job_location}, {job.job_country}</p>
            <p><strong>Job Type</strong>: {job.job_type}</p>
            {/* <p><strong>Salary:</strong> {job.job_salary}</p> */}
            <div className="jd" dangerouslySetInnerHTML={{ __html:job.job_description }}>
            </div>
            </div>
          <div className="mt-4">
            <Link to="/career/update-resume" className="btn btn-c">Apply Now</Link>
            </div>
            </Container>
            </div>
        </>
    );

    }
export default SingleJob;