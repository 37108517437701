import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";

const VisionandMission=()=>{
    const bannerData={
        imgurl:'images/industries.jpg',
        title:"Mission Statement",
        // description:"This is Mission and Vision  page",
    };
    return(
        <div>
                <InternalBanner{...bannerData}/>
    <div className="page-content">
    <Container>
        <h2 className="bbefore"><span>Mission Statement</span></h2>
         
                <p>To lead the mining and heavy equipment industry with a relentless pursuit of engineering excellence, providing cutting-edge solutions that redefine standards and empower our clients.

</p>
        <div className="colspac"></div>
        <h2 className="bbefore"><span>Vision Statement</span></h2>
       
                <p>To be the foremost choice for industries seeking unprecedented quality and reliability in mineral processing equipment. Through visionary innovation and commitment, we aim to engineer the future of mining, setting new benchmarks for operational excellence and customer satisfaction.

</p>

            
        </Container>
        
        </div>
        </div>
        );
}

export default VisionandMission;