import React from "react";

const InternalBanner = ({ imgurl, title, description }) => {
  const background_url = imgurl; // Use imgurl directly
  return (
    <div className="internal-banner text-center" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${background_url})` }}>
      <h1 className="text-white">{title}</h1>
      <h4 className="text-white">{description}</h4>
    </div>
  );
};

export default InternalBanner;
