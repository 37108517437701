import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import { a } from "react-spring";
import { Helmet } from "react-helmet";
const OpenPositions = () => {

    const bannerData = {
        imgurl: '/images/open_pos_banner.jpg',
        // title: "Open Positions",
       
    };
    const apiUrl = process.env.REACT_APP_API_URL;

    const [jobs, setJob] = useState([]);

    useEffect(() => {
        document.title = "Open Positions | Tega Mcnally";
      }, []);

    const job = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/admin/addjob`);
            setJob(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        job();
    }, []);
    // function formatDate(dateString) {
    //     if (!dateString) return ''; // Handle undefined or empty dateString
      
    //     const date = new Date(dateString);
    //     console.log('Date object:', date);
        
    //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //     const formattedDate = date.toLocaleDateString('en-US', options);
        
    //     const day = date.getDate();
    //     const suffix = (day === 1 || day === 21 || day === 31) ? 'st' : (day === 2 || day === 22) ? 'nd' : (day === 3 || day === 23) ? 'rd' : 'th';
      
    //     return formattedDate.replace(/\d{1,2}$/, match => match + suffix);
    //   }
      
    function truncateDescription(description) {
        // Decode HTML entities
        description = decodeURIComponent(description);
      
        // Split the description into words
        const words = description.split(' ');
      
        // Only take the first 30 words
        const truncatedWords = words.slice(0, 20);
      
        // Join the words back into a string
        return truncatedWords.join(' ');
      }

      function datconver(datestr){
        const date = new Date(datestr);
      
        // Formatting the date
        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
      }
      
      
      
    //   console.log(formattedDate);
    return (
        <>
        <Helmet>
<title>Open Positions | Tega Mcnally</title>
  <meta name="title" content="Open Positions | Tega Mcnally"/>
<meta name="description" content="Open Positions"/>

</Helmet>
            <InternalBanner{...bannerData} />
            <Container>
                <div className="page-content">
                    <Row>
                        {jobs.map((job, index) => (
                            <Col key={index} md={10} className="mb-4 shadow p-5 mx-auto">
                               
                                <Link to={job.slug} className="anchorhead"><h2 className="bbefore"><span>{job.job_title}</span></h2></Link>
                                <p>
                                    <strong>Location:</strong> {`${job.job_location[0].toUpperCase()}${job.job_location.slice(1)}`}, {`${job.job_country[0].toUpperCase()}${job.job_country.slice(1)}`}
                                </p>
                                {/* <p><strong>Job Type</strong>: {job.job_type}</p> */}
                                <p><strong>Posted</strong>: {datconver(job.created_at)}</p>
                                {/* <p><strong>Posted:</strong> {formattedDate} </p> */}
                                {/* <div dangerouslySetInnerHTML={{ __html:truncateDescription(job.job_description) }}></div> */}
                                <Link to={job.slug}>
                                    <Button className="btn-c">Read More</Button>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default OpenPositions;