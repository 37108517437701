import React from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";
import InternalBanner from "../../components/global/InternalBanner";

export const RibbonPage = () => {


    const bannerData = {
        imgurl: "../images/industries.jpg",
        title: "Ribbon Page",
        // description: "This is event page",
      };

  return (
    <>
        <InternalBanner {...bannerData} />
        <Container className="single-blog-page">
            <div className="page-content">
                <Row>
                    hello
                </Row>
            </div>
        </Container>
    </>
  )
}
