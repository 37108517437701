import { Container } from "react-bootstrap";
import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const LetsConnect=()=>{
    return(
        <div className="letsc">
            <Container>
                <div className="row">
                    <Col md={9}>
                        <h3>Let's Connect</h3>
                        <p className="mb-0">Have questions or inquiries? We're here to help! Reach out to our dedicated team via email at <Link to="mailto:info@tegaminerals.com">info@tegaminerals.com</Link> or give us a call at <Link to="tel:+91 9073122222">+91 9073122222</Link>.</p>
                    </Col>
                    <Col md={3}>
                    <Link to="/contact-us" className="btn-c float-end">
                      Contact Us<i class="fas fa-long-arrow-alt-right"></i>
                    </Link>
                    </Col>
                </div>
            </Container>
        </div>
    );
}

export default LetsConnect;