import React from "react";
import CarouselComponent from "../components/homepage/CarouselComponent";
import "../css/homepage.css";
import StatsComponent from "../components/homepage/StatsComponent";
import HomeAbout from "../components/homepage/HomeAbout";
import ProductsComponent from "../components/homepage/ProductsComponent";
import Testimonial from "../components/homepage/Testimonial";
import FocusIndustry from "../components/homepage/FocusIndustry";
import MeetPioneers from "../components/homepage/MeetPioneers";
import Blogsec from "../components/homepage/Blogsec";
import LatestNews from "../components/homepage/LatestNews";
import LetsConnect from "../components/homepage/LetsConnect";
import Featured from "../components/homepage/Featured";
import CaseStudy from "../components/homepage/CaseStudy";
import News from "../components/homepage/News";
import FaqHome from "../components/homepage/FaqHome";
import Download from "../components/homepage/Download";
import HomeHero from "../components/homepage/HomeHero";
import { useRef, useState,useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HomeComponent = () => {


    
    return(
        <>
                <HelmetProvider>

        <Helmet>
        <title>Home | Tega Mcnally</title>
          <meta name="title" content="Home | Tega Mcnally"/>
        <meta name="description" content="Tega McNally Minerals (TMML) specializes in manufacturing crushing, grinding, material handling, and mineral processing equipment, serving core industries like iron ore, coal, steel, zinc, copper, limestone, and rare earths for over six decades."/>

  </Helmet>
</HelmetProvider>
        <div className="mainslid">
            <CarouselComponent/>
            </div>
            {/* <div className="colspac"></div>
            <HomeHero/>  */}
            {/* <div className="colspac"></div>
            <StatsComponent/> */}
            <div className="colspac"></div>
            <HomeAbout/>
            <div className="colspac"></div>
            <ProductsComponent/>
            <div className="colspac"></div>
            <FocusIndustry/>        
            <div className="colspac"></div>
            <Featured/>
            {/* <div className="colspac"></div> */}
            {/* <CaseStudy/> */}
            <div className="colspac"></div>
            <Download/>
            {/* <div className="colspac"></div>

            <News/> */}
            
            
            
            
            {/* <Testimonial/> */}
            {/* <div className="colspac"></div> */}
           
            {/* <div className="colspac"></div> */}
            {/* <MeetPioneers/> */}
           
            {/* <div className="colspac"></div>
            <FaqHome/> */}
            <div className="colspac"></div>
            {/* <LatestNews/> */}
            {/* <div className="colspac"></div> */}
            <LetsConnect/>
            {/* <div className="colspac"></div> */}



        </>
    );
}   

export default HomeComponent;