import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../css/navigation.css";
import axios from "axios";

export const Navigation2 = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(apiUrl)

  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchRef = useRef();
  useEffect(() => {
    if (showSearch) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  const [searchedProducts, setSearchedProducts] = useState([]);
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
    if (event.target.value.length > 3) {
      axios
        .get(`${apiUrl}/api/search_product`, {
          params: { name: event.target.value },
        })
        .then((res) => {
          setSearchedProducts(res.data.products);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (searchInput.length < 3) {
      setSearchedProducts([]);
    }
  }, [searchInput]);

  //for link
  const [active, setActive] = useState(null);
  const handleActive = (data) => {
    active === data ? setActive(null) : setActive(data);
  };
  // for sub-menu
  const [activeLink, setActiveLink] = useState(null);
  const handleActiveLink = (data) => {
    activeLink === data ? setActiveLink(null) : setActiveLink(data);
  };

  const handleCloseMenu = () => {
    setActiveLink(null);
    setActive(null);
    setSubLink(null);
  };

  const [hideMenu, setHideMenu] = useState(false);

  const [hbLink, setHbLink] = useState(null);
  const handleHBActiveLink = (data) => {
    hbLink === data ? setHbLink(null) : setHbLink(data);
  };

  const [subhbLink, setSubHbLink] = useState(null);
  const [subSubhbLink, setSubSubHbLink] = useState(null);

  const [subLink, setSubLink] = useState(null);
  const handleSubHBActiveLink = (data) => {
    subhbLink === data ? setSubHbLink(null) : setSubHbLink(data);
  };
  const handleSubSubHBActiveLink = (data) => {
    subSubhbLink === data ? setSubSubHbLink(null) : setSubSubHbLink(data);
  };

  const handleCloseHamburgerMenu = () => {
    setHideMenu(false);
    setActiveLink(null);
    setActive(null);
    setHbLink(null);
    setSubHbLink(null);
    setHideMenu(false);
  };

  const [secondRibbon, setSecondRibbon] = useState(false);
  const [showRibbonOf, setShowRibbonOf] = useState(false);
  const [ofSubRibbon, setOfSubRibbon] = useState(null);

  const handleOfRibbonShow = (ofCate) => {
    setOfSubRibbon(ofCate);
  };

  const handleCloseRibbon = () => {
    setSecondRibbon(false);
    setShowRibbonOf(false);
    setOfSubRibbon(null);
  };

  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    setActiveLink(null);
    setActive(null);
    setHbLink(null);
    setSubHbLink(null);
    setHideMenu(false);
    setSecondRibbon(false);
    setShowRibbonOf(false);
    setOfSubRibbon(null);
    setShowSearch(false);
    setSubLink(null);
    setSearchedProducts([]);
    setSearchInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleCloseSearch = () => {
    setSearchInput("");
    setShowSearch(!showSearch);
    setSearchedProducts("");
  };

  const handleSubLink = (data) => {
    if (data === subLink) {
      setSubLink(null);
    } else {
      setSubLink(data);
    }
  };

  return (
    <header className="theHeader">
      <div className="nav-container">
        <div className="logo">
          <Link to="/" className="navbar-brand">
            <img
              src="/images/tega-TMML-logo-black.png"
              width="100%"

              alt="Logo"
            />
          </Link>
        </div>
        <div className="menu">
          <div className="main-menu">
            <div className="a-menu about-menu">
              <div
                className={`about-menu nav-menu ${active === "home-menu" ? "nav-menu-active" : ""
                  }`}
                onClick={() => handleActive("home-menu")}
              >
                Home <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div
                className={`about-sub-menu mega-menu ${active === "home-menu" ? "show-menu" : ""
                  }`}
              >
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>Home</span>
                </div>
                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <Link to="/">
                      Home
                    </Link>
                    <p
                      className={`nav-menu ${activeLink === "about_us" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("about_us")}
                    >
                      {" "}
                      About Us{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "career" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("career")}
                    >
                      {" "}
                      Career{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                  </div>

                  <div className="sub-menu">
                    <div
                      className={`sub-menu-menu ${activeLink === "about_us" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>About Us</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/about">
                         
                          About-Us
                        </Link>
                        {/* <Link to='/company-overview'><i className="fa fa-building" aria-hidden="true"></i> Company Overview </Link> */}
                        <Link to="/our-team">
                           Our Leadership Team
                        </Link>
                        {/* <Link to="/manufacturing-facilities">
                          <i className="fa fa-industry" aria-hidden="true"></i>{" "}
                          Manufacturing Facilities
                        </Link> */}
                        <Link to="/our-clients">
                         
                          Clientele
                        </Link>
                        {/* <Link to='/quality-assurance'><i className="fa fa-bullseye" aria-hidden="true"></i> Quality Assurance</Link> */}
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "career" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Career</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/career/open-positions">
                          
                          Open Position
                        </Link>
                        <Link to="/career/update-resume">
                          
                          Update Resume
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="a-menu about-menu">
              <div
                className={`about-menu nav-menu ${active === "all-products" ? "nav-menu-active" : ""
                  }`}
                onClick={() => handleActive("all-products")}
              >
                All Products{" "}
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div
                className={`about-sub-menu mega-menu ${active === "all-products" ? "show-menu" : ""
                  }`}
              >
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>All Products</span>
                </div>

                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <p
                      className={`nav-menu ${activeLink === "CRUSHING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("CRUSHING_EQUIPMENTS")}
                    >
                      {" "}
                      Crushing Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "SCREENING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("SCREENING_EQUIPMENTS")}
                    >
                      {" "}
                      Screening Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "GRINDING_EQUIPMENT" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("GRINDING_EQUIPMENT")}
                    >
                      {" "}
                      Grinding Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "FEEDING_EQUIPMENT" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("FEEDING_EQUIPMENT")}
                    >
                      {" "}
                      Feeding Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "CENTRIFUGE" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("CENTRIFUGE")}
                    >
                      {" "}
                      Centrifuge{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "MATERIAL_HANDLING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("MATERIAL_HANDLING_EQUIPMENT")
                      }
                    >
                      {" "}
                      Material Handling Equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "THICKENERS" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("THICKENERS")}
                    >
                      {" "}
                      Thickeners{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "CENTRIFUGAL_SLURRY_PUMP" ? "show-active" : ""}`}onClick={() => handleActiveLink("CENTRIFUGAL_SLURRY_PUMP")}>
                      {" "}
                      Centrifugal Slurry Pump{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "PROCESS_PLANT_EQUIPMENT" ? "show-active": ""}`} onClick={() => handleActiveLink("PROCESS_PLANT_EQUIPMENT")}>
                      {" "}
                      Process Plant Equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                  </div>

                  <div className="sub-menu">
                    <div className={`sub-menu-menu ${activeLink === "CRUSHING_EQUIPMENTS" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Crushing Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Roll Crusher")}>
                            {" "}
                            Roll Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Roll Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/20'}>
                                
                                Single Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/18'}>
                                
                                Smooth Double Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/7'}>
                                
                                Toothed Double Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Corrugated Double Roll Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p
                            onClick={() => handleSubLink("Impactor")}
                          >
                            {" "}
                            Impactor{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Impactor"
                              ? "show-sub-menu"
                              : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Triple Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/12'}>
                                
                                Heavy Duty Impactor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                General Duty Impactor
                              </Link>{" "}
                            </p>

                              <p>
                            <Link to={'/newpro/14'}>
                            {" "}
                            EK Series Impactor
                          </Link>
                          </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Verticle Shaft Impactor
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/22'}>
                          {" "}
                          Hammer Mill
                        </Link>
                        <Link to={'/newpro/21'}>
                          {" "}
                          Ring Granulator
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Jaw Crusher")}>
                            {" "}
                            Jaw Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Jaw Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Single Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/6'}>
                                
                                Double Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Cone Crusher
                        </Link>
                        <Link to={'/newpro/10'}>
                          {" "}
                          Rotary Breaker
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Lump Breaker")}>
                            {" "}
                            Lump Breaker{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Lump Breaker" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/13'}>
                                
                                Chain Mill
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Single Roll Lump Breaker
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Twin Roll Lump Breaker
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Aggregate Crushing Plant Twin Roll Lump Breaker
                        </Link>
                        <Link to="/newpro/23">
                           Skid Mounted Crushing Plant Twin Roll Lump Breaker
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "SCREENING_EQUIPMENTS" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Circular Motion Vibrating Screen")}>
                            {" "}
                            Circular Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Circular Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Dry Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Wet Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p onClick={() => handleSubLink("Linear Motion Vibrating Screen")}>
                            {" "}
                            Linear Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Linear Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Dry Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Wet Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/8'}>
                           HPA Screen - Flip Flop Type
                        </Link>
                        <Link to={'/newpro/11'}>
                          {" "}
                          Roller Screen
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Vibrating Grizzly Screening Feeder
                        </Link>
                        <Link to="/newpro/23">
                           Live Roll Grizzly
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Banana Screen
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Wet Screen")}>
                            {" "}
                            Wet Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Wet Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Dewatering Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Desliming Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Rinsing Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "GRINDING_EQUIPMENT" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Grinding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                           Rod Mill
                        </Link>
                        <Link to="/newpro/23">
                           Ball
                          Mill
                        </Link>
                        <Link to={'/newpro/9'}>
                           Rotary Drum Scrubber
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Vibrating Tube Mill
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "FEEDING_EQUIPMENT" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Feeding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to={'/newpro/17'}>
                          {" "}
                          Apron Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Paddle Feeder
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Vibratory Feeder")}>
                            {" "}
                            Vibratory Feeder{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Vibratory Feeder" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">Vibratory Feeders With Unbalanced Motor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With DF Exciter
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Electro Mechanical Vibratory Feeders
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Reciprocating Feeder
                        </Link>
                        <Link to="/newpro/23">
                           Disc
                          Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Grizzly Feeder
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "CENTRIFUGE"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Centrifuge</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to={'/newpro/4'}>
                          {" "}
                          Solid Bowl Centrifuge
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Coal Centrifuge
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "MATERIAL_HANDLING_EQUIPMENT"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Material Handling Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Travelling Tripper
                        </Link>
                        <Link to="/newpro/23">
                           Belt
                          Conveyer & Components
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "THICKENERS" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Thickeners</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Thickener")}>
                            {" "}
                            Thickner{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Thickener" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                High Rate Thickner
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                High Compression Thickner
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Clarifiers
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "CENTRIFUGAL_SLURRY_PUMP" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Centrifugal Slurry Pump</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump - Heavy Duty
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Vertical Froth Pump
                        </Link>
                        <Link to="/newpro/23">
                           Vertical Sump Pump
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "PROCESS_PLANT_EQUIPMENT"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Process Plant Equipment</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Flotation Cell")}>
                            {" "}
                            Flotation Cell{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Flotation Cell" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                U Tank
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Cell Tank
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Column Flotation Cell
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Pressure Filters
                        </Link>
                        <Link to="/newpro/23">
                           Sand Washing Plant
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Chemical Dosing System
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="a-menu about-menu">
              <div
                className={`about-menu nav-menu ${active === "mining" ? "nav-menu-active" : ""
                  }`}
                onClick={() => handleActive("mining")}
              >
                Mining <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div
                className={`about-sub-menu mega-menu ${active === "mining" ? "show-menu" : ""
                  }`}
              >
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>Mining</span>
                </div>

                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <p
                      className={`nav-menu ${activeLink === "mCRUSHING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("mCRUSHING_EQUIPMENTS")}
                    >
                      {" "}
                      Crushing Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "mSCREENING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("mSCREENING_EQUIPMENTS")}
                    >
                      {" "}
                      Screening Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "mFEEDING_EQUIPMENT" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("mFEEDING_EQUIPMENT")}
                    >
                      {" "}
                      Feeding Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "mCENTRIFUGE" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("mCENTRIFUGE")}
                    >
                      {" "}
                      Centrifuge{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "mMATERIAL_HANDLING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("mMATERIAL_HANDLING_EQUIPMENT")
                      }
                    >
                      {" "}
                      Material Handling Equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "mTHICKENERS" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("mTHICKENERS")}
                    >
                      {" "}
                      Thickeners{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>


                     

                  </div>

                  <div className="sub-menu">
                    <div
                      className={`sub-menu-menu ${activeLink === "mCRUSHING_EQUIPMENTS" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Crushing Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Roll Crusher")}>
                            {" "}
                            Roll Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Roll Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/20'}>
                                
                                Single Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/18'}>
                                
                                Smooth Double Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/7'}>
                                
                                Toothed Double Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Corrugated Double Roll Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p
                            onClick={() => handleSubLink("Impactor")}
                          >
                            {" "}
                            Impactor{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Impactor"
                              ? "show-sub-menu"
                              : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Triple Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/12'}>
                                
                                Heavy Duty Impactor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                General Duty Impactor
                              </Link>{" "}
                            </p>
                              <p>
                                <Link to={'/newpro/14'}>
                                {" "}
                                EK Series Impactor
                                </Link>
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/22'}>
                          {" "}
                          Hammer Mill
                        </Link>
                        <Link to={'/newpro/21'}>
                          {" "}
                          Ring Granulator
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Jaw Crusher")}>
                            {" "}
                            Jaw Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Jaw Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Single Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/6'}>
                                
                                Double Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Cone Crusher
                        </Link>
                        <Link to={'/newpro/10'}>
                          {" "}
                          Rotary Breaker
                        </Link>
                        <Link to="/newpro/23"> Skid Mounted Crushing Plant Twin Roll Lump Breaker</Link>
                      </div>

                    </div>
                    <div
                      className={`sub-menu-menu ${activeLink === "mSCREENING_EQUIPMENTS"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Circular Motion Vibrating Screen")}>
                            {" "}
                            Circular Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Circular Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Dry Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Wet Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p onClick={() => handleSubLink("Linear Motion Vibrating Screen")}>
                            {" "}
                            Linear Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Linear Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Dry Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Wet Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/8'}>
                           HPA Screen - Flip
                          Flop Screen
                        </Link>
                        <Link to={'/newpro/11'}>
                          {" "}
                          Roller Screen
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Vibrating Grizzly Screening Feeder
                        </Link>
                        <Link to="/newpro/23">
                           Live Roll Grizzly
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Banana Screen
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "mFEEDING_EQUIPMENT" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Feeding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to={'/newpro/17'}>
                          {" "}
                          Apron Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Paddle Feeder
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Vibratory Feeder")}>
                            {" "}
                            Vibratory Feeders{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Vibratory Feeder" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With Unbalanced Motor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With DF Exciter
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Electro Mechanical Vibratory Feeders
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Reciprocating Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Grizzly Feeder
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "mCENTRIFUGE"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Centrifuge</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Coal Centrifuge
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "mMATERIAL_HANDLING_EQUIPMENT"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Material Handling Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                           Belt
                          Conveyer & Components
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "mTHICKENERS" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Thickeners</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Thickener")}>
                            {" "}
                            Thickner{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Thickener" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                High Rate Thickner
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                High Compression Thickner
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="a-menu about-menu">
              <div className={`about-menu nav-menu ${active === "mineral" ? "nav-menu-active" : ""}`}onClick={() => handleActive("mineral")}>
                Mineral Processing{" "}
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div className={`about-sub-menu mega-menu ${active === "mineral" ? "show-menu" : ""}`}>
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>Mineral Processing</span>
                </div>

                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <p
                      className={`nav-menu ${activeLink === "minCRUSHING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("minCRUSHING_EQUIPMENTS")}
                    >
                      {" "}
                      Crushing equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "minSCREENING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("minSCREENING_EQUIPMENTS")
                      }
                    >
                      {" "}
                      Screening equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "minGRINDING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("minGRINDING_EQUIPMENT")}
                    >
                      {" "}
                      Grinding equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "minFEEDING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("minFEEDING_EQUIPMENT")}
                    >
                      {" "}
                      Feeding equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "minCENTRIFUGE" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("minCENTRIFUGE")}
                    >
                      {" "}
                      Centrifuge{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "minMATERIAL_HANDLING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("minMATERIAL_HANDLING_EQUIPMENT")
                      }
                    >
                      {" "}
                      Material handling equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "minTHICKENERS" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("minTHICKENERS")}
                    >
                      {" "}
                      Thickeners{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "minCENTRIFUGAL_SLURRY_PUMP" ? "show-active" : ""}`}onClick={() =>handleActiveLink("minCENTRIFUGAL_SLURRY_PUMP")}>
                      {" "}Centrifugal Slurry Pump{" "}<i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>


                    <p className={`nav-menu ${activeLink === "minPROCESS_PLANT_EQUIPMENT" ? "show-active" : ""}`} onClick={() =>handleActiveLink("minPROCESS_PLANT_EQUIPMENT")}>
                      {" "}
                      Process Plant Equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>


                  </div>

                  <div className="sub-menu">
                    <div className={`sub-menu-menu ${activeLink === "minCRUSHING_EQUIPMENTS"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Crushing Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Jaw Crusher")}>
                            {" "}
                            Jaw Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Jaw Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Single Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/6'}>
                                
                                Double Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Cone Crusher
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Aggregate Crushing Plant Twin Roll Lump Breaker
                        </Link>
                      </div>
                    </div>
                    <div className={`sub-menu-menu ${activeLink === "minSCREENING_EQUIPMENTS"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Circular Motion Vibrating Screen")}>
                            {" "}
                            Circular Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Circular Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Dry Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Wet Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p onClick={() => handleSubLink("Linear Motion Vibrating Screen")}>
                            {" "}
                            Linear Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Linear Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Dry Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Wet Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/8'}>
                           HPA Screen - Flip
                          Flop Screen
                        </Link>
                        <Link to={'/newpro/11'}>
                          {" "}
                          Roller Screen
                        </Link>
                        <Link to="/newpro/23">
                           Live Roll Grizzly
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Banana Screen
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Wet Screen")}>
                            {" "}
                            Wet Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Wet Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Dewatering Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Desliming Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minGRINDING_EQUIPMENT" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Grinding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                           Rod Mill
                        </Link>
                        <Link to="/newpro/23">
                           Ball
                          Mill
                        </Link>
                        <Link to={'/newpro/9'}>
                           Rotary Drum Scrubber
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minFEEDING_EQUIPMENT" ? "show-menu" : "" }`} >
                      <div className="title bbefore">
                        <span>Feeding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Vibratory Feeder")}>
                            {" "}
                            Vibratory Feeders{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Vibratory Feeder" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With Unbalanced Motor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With DF Exciter
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Electro Mechanical Vibratory Feeders
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Reciprocating Feeder
                        </Link>
                        <Link to="/newpro/23">
                           Disc
                          Feeder
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minCENTRIFUGE" ? "show-menu" : "" }`} >
                      <div className="title bbefore">
                        <span>Centrifuge</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to={'/newpro/4'}>
                          {" "}
                          Solid Bowl Centrifuge
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minMATERIAL_HANDLING_EQUIPMENT" ? "show-menu" : "" }`} >
                      <div className="title bbefore">
                        <span>Material Handling Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                           Belt
                          Conveyer & Components
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minTHICKENERS" ? "show-menu" : "" }`} >
                      <div className="title bbefore">
                        <span>Thickeners</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Thickener")}>
                            {" "}
                            Thickner{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Thickener" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                High Compression Thickner
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                      className={`sub-menu-menu ${activeLink === "minCENTRIFUGAL SLURRY PUMP" ? "show-menu" : ""
                        }`}
                      >
                      <div className="title bbefore">
                        <span>Centrifugal Slurry Pump</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump - Heavy Duty
                        </Link>
                        <Link to="/newpro/23">
                           Vertical Sump Pump
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "minPROCESS_PLANT_EQUIPMENT"
                        ? "show-menu"
                        : ""
                        }`}>
                      <div className="title bbefore">
                        <span>Process Plant Equipment</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Flotation Cell")}>
                            {" "}
                            Flotation Cell{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Flotation Cell" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                U Tank
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Cell Tank
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Column Flotation Cell
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Pressure Filters
                        </Link>
                        <Link to="/newpro/23">
                           Sand Washing Plant
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Chemical Dosing System
                        </Link>
                      </div>
                    </div>


                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minCENTRIFUGAL_SLURRY_PUMP" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Centrifugal Slurry Pump</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump - Heavy Duty
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Vertical Froth Pump
                        </Link>
                        <Link to="/newpro/23">
                           Vertical Sump Pump
                        </Link>
                      </div>
                    </div>

                    <div className={`sub-menu-menu ${activeLink === "minPROCESS_PLANT_EQUIPMENT"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Process Plant Equipment</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Flotation Cell")}>
                            {" "}
                            Flotation Cell{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Flotation Cell" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                U Tank
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Cell Tank
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Column Flotation Cell
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Pressure Filters
                        </Link>
                        <Link to="/newpro/23">
                           Sand Washing Plant
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Chemical Dosing System
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Bulk Material Handeling */}
            
            {/* <div className="a-menu about-menu">
              <div className={`about-menu nav-menu ${active === "bulk_material_handeling" ? "nav-menu-active" : ""}`}onClick={() => handleActive("bulk_material_handeling")}>
                Bulk Material Handeling {" "}
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div className={`about-sub-menu mega-menu ${active === "bulk_material_handeling" ? "show-menu" : ""}`}>
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>Bulk Material Handeling</span>
                </div>

                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <p className={`nav-menu ${activeLink === "bulkCRUSHING_EQUIPMENTS" ? "show-active": ""}`} onClick={() => handleActiveLink("bulkCRUSHING_EQUIPMENTS")}>
                      {" "}
                      Crushing Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "buklSCREENING_EQUIPMENTS"? "show-active": ""}`}onClick={() => handleActiveLink("buklSCREENING_EQUIPMENTS")}>
                      {" "}
                      Screening Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "buklGRINDING_EQUIPMENTS"? "show-active": ""}`}onClick={() => handleActiveLink("buklGRINDING_EQUIPMENTS")}>
                      {" "}
                      Grinding Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "bulkFEEDING_EQUIPMENT" ? "show-active" : ""}`}onClick={() => handleActiveLink("bulkFEEDING_EQUIPMENT")}>
                      {" "}
                      Feeding Equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "bulkCENTRIFUGE" ? "show-active" : "" }`} onClick={() => handleActiveLink("bulkCENTRIFUGE")}>
                      {" "}
                      Centrifuge{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "bulkMATERIAL_HANDLING_EQUIPMENT" ? "show-active" : "" }`} onClick={() => handleActiveLink("bulkMATERIAL_HANDLING_EQUIPMENT")}>
                      {" "}
                      Material Handeling Equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p className={`nav-menu ${activeLink === "bulkTHICKENERS" ? "show-active" : "" }`} onClick={() => handleActiveLink("bulkTHICKENERS")}>
                      {" "}
                      Thickeners{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                  </div>
                  <div className="sub-menu">
                    <div className={`sub-menu-menu ${activeLink === "bulkCRUSHING_EQUIPMENTS" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Crushing Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Roll Crusher")}>
                            {" "} Roll Crusher{" "}<i className="fa fa-caret-down"aria-hidden="true"></i>
                          </p>
                          <div className={`sub-sub-menu ${subLink === "Roll Crusher" ? "show-sub-menu" : ""}`}>
                            <p><Link to={'/newpro/20'}> <i className="fa fa-cogs" aria-hidden="true" ></i>{" "} Single Roll Crusher</Link>{" "} </p>
                            <p>
                              <Link to={'/newpro/18'}>
                                
                                Smooth Double Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Toothed Double Roll Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Corrugated Double Roll Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p onClick={() => handleSubLink("Impactor")}>
                            {" "}Impactor{" "} <i className="fa fa-caret-down"aria-hidden="true"></i></p>
                          <div className={`sub-sub-menu ${subLink === "Impactor"? "show-sub-menu": ""}`}>
                            <p> 
                              <Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Triple Roll Crusher</Link>{" "}</p><p>
                              <Link to={'/newpro/12'}><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Heavy Duty Impactor</Link>{" "}
                            </p>
                            <p><Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}General Duty Impactor</Link>{" "}</p>
                          </div>
                        </div>

                        <Link to={'/newpro/22'}>
                          {" "}
                          Hammer Mill
                        </Link>
                        <Link to={'/newpro/21'}>
                          {" "}
                          Ring Granulator
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Jaw Crusher")}>
                            {" "}
                            Jaw Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Jaw Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Single Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Double Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/10'}>
                          {" "}
                          Rotary Breaker
                        </Link>

                        <div>
                          <p onClick={() => handleSubLink("bulkLump_breaker")}>
                            {" "}Lump Breaker{" "} <i className="fa fa-caret-down"aria-hidden="true"></i></p>
                          <div className={`sub-sub-menu ${subLink === "bulkLump_breaker"? "show-sub-menu": ""}`}>
                            <p><Link to={'/newpro/13'}><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Chain Mill</Link>{" "}</p>
                            <p><Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Single Roll Lump Breaker</Link>{" "}</p>
                            <p><Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Twin Roll Lump Breaker</Link>{" "}</p>
                          </div>
                        </div>
                        <Link to="/newpro/23"> Skid Mounted Crushing Plant Twin Roll Lump Breaker</Link>
                      </div>

                    </div>
                    <div className={`sub-menu-menu ${activeLink === "buklSCREENING_EQUIPMENTS"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Circular Motion Vibrating Screen")}>
                            {" "} Circular Motion Vibrating Screen{" "} <i className="fa fa-caret-down"aria-hidden="true"></i>
                          </p>
                          <div className={`sub-sub-menu ${subLink === "Circular Motion Vibrating Screen" ? "show-sub-menu" : ""}`}>
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Dry Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Wet Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p onClick={() => handleSubLink("Linear Motion Vibrating Screen")}>
                            {" "}
                            Linear Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Linear Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Dry Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Wet Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/8'}>
                           HPA Screen - Flip
                          Flop Screen
                        </Link>
                        <Link to={'/newpro/11'}>
                          {" "}
                          Roller Screen
                        </Link>
                        <Link to="/newpro/23">  Live Roll Grizzly</Link>
                        <div>
                          <p onClick={() => handleSubLink("bulkWet_screen")}>
                            {" "}Wet Screen{" "} <i className="fa fa-caret-down"aria-hidden="true"></i></p>
                          <div className={`sub-sub-menu ${subLink === "bulkWet_screen"? "show-sub-menu": ""}`}>
                            <p><Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Dewatering Screen</Link>{" "}</p>
                            <p><Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Desliming Screen</Link>{" "}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`sub-menu-menu ${activeLink === "buklGRINDING_EQUIPMENTS"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23"><i className="fa fa-cogs"aria-hidden="true"></i>{" "}Vibrating Tube Mill</Link>{" "}
                      </div>
                    </div>
                    <div className={`sub-menu-menu ${activeLink === "bulkFEEDING_EQUIPMENT" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Feeding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to={'/newpro/17'}>
                          {" "}
                          Apron Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Paddle Feeder
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Vibratory Feeder")}>
                            {" "}
                            Vibratory Feeder{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Vibratory Feeder" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With Unbalanced Motor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With DF Exciter
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Electro Mechanical Vibratory Feeders
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Reciprocating Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Disc Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Grizzly Feeder
                        </Link>
                      </div>
                    </div>
                    <div className={`sub-menu-menu ${activeLink === "bulkCENTRIFUGE" ? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Centrifuge</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Coal Centrifuge
                        </Link>
                      </div>
                    </div>
                    <div className={`sub-menu-menu ${activeLink === "bulkMATERIAL_HANDLING_EQUIPMENT"? "show-menu": ""}`}>
                      <div className="title bbefore">
                        <span>Material Handling Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                           Belt
                          Travelling Tripper
                        </Link>
                        <Link to="/newpro/23">
                           Belt
                          Conveyer & Components
                        </Link>
                      </div>
                    </div>
                    <div className={`sub-menu-menu ${activeLink === "bulkTHICKENERS" ? "show-menu" : ""}`}>
                      <div className="title bbefore">
                        <span>Thickeners</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Thickener")}>
                            {" "}
                            Thickner{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Thickener" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                High Compression Thickner
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="a-menu about-menu">
              <div
                className={`about-menu nav-menu ${active === "agg" ? "nav-menu-active" : ""
                  }`}
                onClick={() => handleActive("agg")}
              >
                Aggregate{" "}
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div
                className={`about-sub-menu mega-menu ${active === "agg" ? "show-menu" : ""
                  }`}
              >
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>Aggregate</span>
                </div>

                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <p
                      className={`nav-menu ${activeLink === "aggCRUSHING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("aggCRUSHING_EQUIPMENTS")}
                    >
                      {" "}
                      Crushing equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "aggSCREENING_EQUIPMENTS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("aggSCREENING_EQUIPMENTS")
                      }
                    >
                      {" "}
                      Screening equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "aggFEEDING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() => handleActiveLink("aggFEEDING_EQUIPMENT")}
                    >
                      {" "}
                      Feeding equipments{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>

                    <p
                      className={`nav-menu ${activeLink === "aggMATERIAL_HANDLING_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("aggMATERIAL_HANDLING_EQUIPMENT")
                      }
                    >
                      {" "}
                      Material handling equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "aggTHICKENERS"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("aggTHICKENERS")
                      }
                    >
                      {" "}
                      Thickeners{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "aggCENTRIFUGAL SLURRY PUMP"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("aggCENTRIFUGAL SLURRY PUMP")
                      }
                    >
                      {" "}
                      Centrifugal Slurry Pump{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "aggPROCESS_PLANT_EQUIPMENT"
                        ? "show-active"
                        : ""
                        }`}
                      onClick={() =>
                        handleActiveLink("aggPROCESS_PLANT_EQUIPMENT")
                      }
                    >
                      {" "}
                      Process Plant Equipment{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                  </div>

                  <div className="sub-menu">
                    <div
                      className={`sub-menu-menu ${activeLink === "aggCRUSHING_EQUIPMENTS"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Crushing Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Roll Crusher")}>
                            {" "}
                            Roll Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Roll Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/18'}>
                                
                                Smooth Double Roll Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p
                            onClick={() => handleSubLink("Impactor")}
                          >
                            {" "}
                            Impactor{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Impactor"
                              ? "show-sub-menu"
                              : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/12'}>
                                
                                Heavy Duty Impactor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Verticle Shaft Impactor
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p onClick={() => handleSubLink("Jaw Crusher")}>
                            {" "}
                            Jaw Crusher{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Jaw Crusher" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Single Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/6'}>
                                
                                Double Toggle Jaw Crusher
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Cone Crusher
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Aggregate Crushing Plant Twin Roll Lump Breaker
                        </Link>
                        <Link to="/newpro/23">
                           Skid Mounted Crushing Plant Twin Roll Lump Breaker
                        </Link>
                      </div>
                    </div>
                    <div
                      className={`sub-menu-menu ${activeLink === "aggSCREENING_EQUIPMENTS"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Circular Motion Vibrating Screen")}>
                            {" "}
                            Circular Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Circular Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Dry Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/19'}>
                                
                                Wet Circular Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p onClick={() => handleSubLink("Linear Motion Vibrating Screen")}>
                            {" "}
                            Linear Motion Vibrating Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Linear Motion Vibrating Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Dry Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to={'/newpro/15'}>
                                
                                Wet Linear Motion Vibrating Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to={'/newpro/8'}>
                           HPA Screen - Flip
                          Flop Screen
                        </Link>
                        <div>
                          <p onClick={() => handleSubLink("Wet Screen")}>
                            {" "}
                            Wet Screen{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Wet Screen" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Rinsing Screen
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div
                      className={`sub-menu-menu ${activeLink === "aggFEEDING_EQUIPMENT" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Feeding Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Vibratory Feeder")}>
                            {" "}
                            Vibratory Feeder{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Vibratory Feeder" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With Unbalanced Motor
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Vibratory Feeders With DF Exciter
                              </Link>{" "}
                            </p>
                            <p>
                              <Link to="/newpro/23">
                                
                                Electro Mechanical Vibratory Feeders
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <Link to="/newpro/23">
                          {" "}
                          Reciprocating Feeder
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Grizzly Feeder
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "aggMATERIAL_HANDLING_EQUIPMENT"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Material Handling Equipments</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Travelling Tripper
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "aggTHICKENERS" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Thickeners</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <div>
                          <p onClick={() => handleSubLink("Thickener")}>
                            {" "}
                            Thickner{" "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className={`sub-sub-menu ${subLink === "Thickener" ? "show-sub-menu" : ""
                              }`}
                          >
                            <p>
                              <Link to="/newpro/23">
                                
                                High Rate Thickner
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "aggCENTRIFUGAL SLURRY PUMP" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Centrifugal Slurry Pump</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Horizontal Slurry Pump - Heavy Duty
                        </Link>
                        <Link to="/newpro/23">
                           Vertical Sump Pump
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "aggPROCESS_PLANT_EQUIPMENT"
                        ? "show-menu"
                        : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Process Plant Equipment</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/newpro/23">
                           Sand Washing Plant
                        </Link>
                        <Link to="/newpro/23">
                          {" "}
                          Chemical Dosing System
                        </Link>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="a-menu insights-menu">
              <div
                className={`insight-menu nav-menu ${active === "insight-menu" ? "nav-menu-active" : ""
                  }`}
                onClick={() => handleActive("insight-menu")}
              >
                Insights <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
              <div
                className={`insights-sub-menu mega-menu ${active === "insight-menu" ? "show-menu" : ""
                  }`}
              >
                <div className="close-button" onClick={handleCloseMenu}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="title bbefore">
                  <span>Insights</span>
                </div>
                <div className="mega-menu-sub-menu">
                  <div className="mega-menu-menu">
                    <p
                      className={`nav-menu ${activeLink === "newsroom" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("newsroom")}
                    >
                      {" "}
                      Newsroom{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <p
                      className={`nav-menu ${activeLink === "media" ? "show-active" : ""
                        }`}
                      onClick={() => handleActiveLink("media")}
                    >
                      <i className="fa fa-play" aria-hidden="true"></i> Media{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </p>
                    <Link to="/blogs">
                      <i className="fa fa-rss" aria-hidden="true"></i> Blog
                    </Link>
                    <Link to="/downloads">
                      <i className="fa fa-download" aria-hidden="true"></i>{" "}
                      Downloads
                    </Link>
                  </div>
                  <div className="sub-menu">
                    <div
                      className={`sub-menu-menu ${activeLink === "media" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Media</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link to="/media">
                          {/* <i className="fa fa-camera" aria-hidden="true"></i>{" "} */}
                          Image
                        </Link>
                        <Link to="/media-video">
                          {/* <i
                            className="fa fa-video-camera"
                            aria-hidden="true"
                          ></i>{" "} */}
                          Video
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`sub-menu-menu ${activeLink === "newsroom" ? "show-menu" : ""
                        }`}
                    >
                      <div className="title bbefore">
                        <span>Newsroom</span>
                      </div>
                      <div className="sub-menu-sub-menu">
                        <Link
                          to="/newsroom/company-announcement"
                          onClick={handleCloseMenu}
                        >
                          {" "}
                          Company Announcement
                        </Link>
                        <Link
                          to="/newsroom/news-events"
                          onClick={handleCloseMenu}
                        >
                          {" "}
                          News Events
                        </Link>
                        <Link
                          to="/newsroom/media-coverage"
                          onClick={handleCloseMenu}
                        >
                          {" "}
                          Media Coverage
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="a-menu">
              <Link to="/contact-us">Contacts</Link>
            </div>

            <div className="a-menu search-button" onClick={handleCloseSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </div>

            {/* <div className="second-ribbon-button" onClick={()=>setSecondRibbon(!secondRibbon)}>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </div> */}
          </div>

          <div
            className="hamburger-button"
            onClick={() => setHideMenu(!hideMenu)}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
        </div>

        <div className={`hamburger-menu ${hideMenu ? "hide-menu" : ""}`}>
          <div className="hamburger-close-menu">
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={handleCloseHamburgerMenu}
            ></i>
          </div>

          <div className="hamburger-menu-menu">
            <div className="hamburger-menu-items">
              <div className="hamburger-menu-div">
                <p
                  className={`${hbLink === "hb_home" ? "nav-menu-active" : ""}`}
                  onClick={() => handleHBActiveLink("hb_home")}
                >
                  {" "}
                  Home <i className="fa fa-caret-down" aria-hidden="true"></i>
                </p>
                <div className="hamburger-sub-menu">
                  <div
                    className={`hamburger-master-sub ${hbLink === "hb_home" ? "show-menu" : ""
                      }`}
                  >
                    <div className="hamburger-menu-div">
                      <Link className="w-100" to={"/"}>
                        Home
                      </Link>
                      <p
                        className={`${subhbLink === "sub_about_us" ? "nav-menu-active" : ""
                          }`}
                        onClick={() => handleSubHBActiveLink("sub_about_us")}
                      >
                        {" "}
                        About Us{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <div
                        className={`hamburger-master-sub ${subhbLink === "sub_about_us" ? "show-menu" : ""
                          }`}
                      >
                        <div className="hamburger-sub-menu">
                          <Link to="/about">
                           
                            About-Us
                          </Link>
                          <Link to="/our-team">
                           
                            Our Leadership Team
                          </Link>
                          {/* <Link to="/manufacturing-facilities">
                            <i
                              className="fa fa-industry"
                              aria-hidden="true"
                            ></i>{" "}
                            Manufacturing Facilities
                          </Link> */}
                          <Link to="/our-clients">
                           
                            Clientele
                          </Link>
                        </div>
                      </div>
                      <p
                        className={`${subhbLink === "sub_career" ? "nav-menu-active" : ""
                          }`}
                        onClick={() => handleSubHBActiveLink("sub_career")}
                      >
                        {" "}
                        Career{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <div
                        className={`hamburger-master-sub ${subhbLink === "sub_career" ? "show-menu" : ""
                          }`}
                      >
                        <div className="hamburger-sub-menu">
                          <Link to="/career/open-positions">
                            
                            Open Position
                          </Link>
                          <Link to="/career/update-resume">
                           
                            Update Resume
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hamburger-menu-div">
                <p className={`${hbLink === "hb_all_products" ? "nav-menu-active" : ""}`} onClick={() => handleHBActiveLink("hb_all_products")}> All Products{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                <div className={`hamburger-master-sub ${hbLink === "hb_all_products" ? "show-menu" : ""}`}>
                  <div className={`hamburger-sub-menu `}>
                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-Crushing_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-Crushing_Equipments")}> Crushing Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-Crushing_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to={'/newpro/20'}>
                            {" "}
                            Single Roll Crusher
                          </Link>

                          <p className={`${subSubhbLink === "sub-Double_Roll" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-Double_Roll")}>{" "}Double Roll Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-Double_Roll" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to={'/newpro/18'}>Smooth Double Roll Crusher</Link>
                              <Link to={'/newpro/7'}>Toothed Double Roll Crusher</Link>
                              <Link to='#'>Corrugated Double Roll Crusher</Link>
                            </div>
                          </div>

                          <Link to="/newpro/23">
                            {" "}
                            Triple Roll Crusher
                          </Link>
                          <Link to={'/newpro/12'}>
                            {" "}
                            Heavy Duty Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            General Duty Impactor
                          </Link>
                          <Link to={'/newpro/14'}>
                            {" "}
                            EK Series Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Swing Hammer Reversible Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Reversible Hammer Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            One Way Hammer Mill
                          </Link>
                          <Link to={'/newpro/21'}>
                            {" "}
                            Ring Granulator
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Corn Crusher
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Verticle Shaft Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Coke Cutter
                          </Link>
                          <Link to={'/newpro/10'}>
                            {" "}
                            Rotary Breaker
                          </Link>
                          <Link to={'/newpro/13'}>
                            {" "}
                            Chain Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Lump Loosener
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Single Roll Lump Breaker
                          </Link>

                          <p className={`${subSubhbLink === "sub-Jaw_Crusher" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-Jaw_Crusher")}>{" "}Jaw Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-Jaw_Crusher" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Single Toggle</Link>
                              <a href='/newpro/6'>Double Toggle</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-Grinding_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-Grinding_Equipments")} >Grinding Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-Grinding_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Rod Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Ball Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Vibrating Tube Mill
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p
                        className={`${subhbLink === "sub-Screening_Equipments"
                          ? "nav-menu-active"
                          : ""
                          }`}
                        onClick={() =>
                          handleSubHBActiveLink("sub-Screening_Equipments")
                        }
                      >
                        Screening Equipments{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <div
                        className={`hamburger-master-sub ${subhbLink === "sub-Screening_Equipments"
                          ? "show-menu"
                          : ""
                          }`}
                      >
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Circular Motion Vibrating Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Linear Motion Vibrating Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Flip Flop Screen
                          </Link>
                          <Link to={'/newpro/11'}>
                            {" "}
                            Roller Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Vibrating Grizzly Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            High Particle Acceleration Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Banana Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Wet Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Screening Feeder
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p
                        className={`${subhbLink === "sub-Feeding_Equipments"
                          ? "nav-menu-active"
                          : ""
                          }`}
                        onClick={() =>
                          handleSubHBActiveLink("sub-Feeding_Equipments")
                        }
                      >
                        Feeding Equipments{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <div
                        className={`hamburger-master-sub ${subhbLink === "sub-Feeding_Equipments"
                          ? "show-menu"
                          : ""
                          }`}
                      >
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Arpon Feeer
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Paddle Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Mechanical Vibratory Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Reciprocating Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Disc Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Grizzly Feeder
                          </Link>
                        </div>
                      </div>
                    </div>

                    <a href="/newpro/4">Centrifuge</a>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-Material_Handling" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-Material_Handling")}>Material Handling Equipment{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-Material_Handling" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Bucket Elevator
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Tripper
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Belt Conveyer & Components
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Screw Conveyor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Power Roller Conveyor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Wagon Tippler
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Side Arm Charger
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Stacker Reclaimer
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Gravity Roller Comveyor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Eot Crane
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-thickners" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-thickners")}>Thickners{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-thickners" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            High Rated Thickner
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Clarifiers
                          </Link>
                        </div>
                      </div>
                    </div>

                    <Link to="/newpro/4">
                      {" "}Slurry Pump
                    </Link>

                    <div className="hamburger-menu-div">
                      <p
                        className={`${subhbLink === "sub-Process_Plant"
                          ? "nav-menu-active"
                          : ""
                          }`}
                        onClick={() =>
                          handleSubHBActiveLink("sub-Process_Plant")
                        }
                      >
                        Process Plant Equipments{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <div
                        className={`hamburger-master-sub ${subhbLink === "sub-Process_Plant" ? "show-menu" : ""
                          }`}
                      >
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Flotation Cell
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Pressure Filters
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Aggregate Plant
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Skid Plant
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Rotary Scrubber
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="hamburger-menu-div">
                      <p
                        className={`${subhbLink === "sub-Tailor-Build"
                          ? "nav-menu-active"
                          : ""
                          }`}
                        onClick={() =>
                          handleSubHBActiveLink("sub-Tailor-Build")
                        }
                      >
                        Tailor Build{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <div
                        className={`hamburger-master-sub ${subhbLink === "sub-Tailor-Build" ? "show-menu" : ""
                          }`}
                      >
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Pressure Vessel
                          </Link>
                          <Link to="/newpro/23">
                             Heat
                            Exchanger
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Rotary Kiln
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Dryer & Cooler
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Scrubber
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Transfer Car
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Tundish Car
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Water Seal Check Valve
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Laddle
                          </Link>
                          <Link to="/newpro/23">
                             Sand
                            Washing Plant
                          </Link>

                          <p className={`${subSubhbLink === "sub-for_Steel_Plant" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-for_Steel_Plant")}>{" "}Equipments for Steel Plan<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-for_Steel_Plant" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Equipment For Billet & Bloom Caster</Link>
                              <Link to='#'>Whell & Skid Mounted Crushing Plant</Link>
                              <Link to='#'>Equipment For Converter</Link>
                              <Link to='#'>Equipment For Blast Furnace</Link>
                            </div>
                          </div>

                          <p className={`${subSubhbLink === "sub-port_handeling" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-port_handeling")}>{" "}Port Handling Equipment<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-port_handeling" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Barge Unloader</Link>
                              <Link to='#'>Container Handling Crane</Link>
                              <Link to='#'>Bulk Handling Crane</Link>
                              <Link to='#'>Shipyard Crane</Link>
                            </div>
                          </div>

                          <p className={`${subSubhbLink === "sub-opencast_mining_equp" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-opencast_mining_equp")}>{" "}Opencast Mining Equipment<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-opencast_mining_equp" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Bucket Wheel Excavator</Link>
                              <Link to='#'>Crawler Mounted Spreader</Link>
                              <Link to='#'>Shiftable Conveyor</Link>
                            </div>
                          </div>

                          <a href="/newpro/4">
                            {" "}
                            Centrifuge
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="hamburger-menu-div">
                <p className={`${hbLink === "hb_mining" ? "nav-menu-active" : ""}`} onClick={() => handleHBActiveLink("hb_mining")}> Mining{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                <div className={`hamburger-master-sub ${hbLink === "hb_mining" ? "show-menu" : ""}`}>
                  <div className={`hamburger-sub-menu `}>
                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Crushing_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Crushing_Equipments")}> Crushing Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Crushing_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to={'/newpro/20'}>
                            {" "}
                            Single Roll Crusher
                          </Link>
                          <p className={`${subSubhbLink === "sub-min-Double_Roll" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-min-Double_Roll")}>{" "}Double Roll Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-min-Double_Roll" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to={'/newpro/18'}>Smooth Double Roll Crusher</Link>
                              <Link to={'/newpro/7'}>Toothed Double Roll Crusher</Link>
                              <Link to='#'>Corrugated Double Roll Crusher</Link>
                            </div>
                          </div>
                          <Link to="/newpro/23">
                            {" "}
                            Triple Roll Crusher
                          </Link>
                          <Link to={'/newpro/12'}>
                            {" "}
                            Heavy Duty Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            General Duty Impactor
                          </Link>
                          <Link to={'/newpro/14'}>
                            {" "}
                            EK Series Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Swing Hammer Reversible Impactor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Reversible Hammer Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            One Way Hammer Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Corn Crusher
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Coke Cutter
                          </Link>
                          <Link to={'/newpro/10'}>
                            {" "}
                            Rotary Breaker
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Single Roll Lump Breaker
                          </Link>
                          <p className={`${subSubhbLink === "sub-mill-Jaw_Crusher" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-mill-Jaw_Crusher")}>{" "}Jaw Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-mill-Jaw_Crusher" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Single Toggle</Link>
                              <a href='/newpro/6'>Double Toggle</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Screening_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Screening_Equipments")}>Screening Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Screening_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Circular Motion Vibrating Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Linear Motion Vibrating Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Flip Flop Screen
                          </Link>
                          <Link to={'/newpro/11'}>
                            {" "}
                            Roller Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Vibrating Grizzly Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            High Particle Acceleration Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Banana Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Wet Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Screening Feeder
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Grinding_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Grinding_Equipments")}>Grinding Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Grinding_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Rod Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Ball Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Vibrating Tube Mill
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Feeding_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Feeding_Equipments")}>Feeding Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Feeding_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Arpon Feeer
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Mechanical Vibratory Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Reciprocating Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Grizzly Feeder
                          </Link>
                        </div>
                      </div>
                    </div>

                    <a href="/newpro/4">Centrifuge</a>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Material_Handeling_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Material_Handeling_Equipments")}>Material Handling Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Material_Handeling_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Bucket Elevator
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Tripper
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Belt Conveyer & Components
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Screw Conveyor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Power Roller Conveyor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Wagon Tippler
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Side Arm Charger
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Stacker Reclaimer
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Gravity Roller Comveyor
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Eot Crane
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Thickner" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Thickner")}>Thickners{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Thickner" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            High Rated Thickner
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Clarifiers
                          </Link>
                        </div>
                      </div>
                    </div>

                    <Link to="/newpro/4">
                      {" "}Slurry Pump
                    </Link>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Process_Plant" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Process_Plant")}>Process Plant Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Process_Plant" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Flotation Cell
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Pressure Filters
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Aggregate Plant
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Skid Plant
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Rotary Scrubber
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-mill-Tailor_Build" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-mill-Tailor_Build")}>Tailor Build{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-mill-Tailor_Build" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Pressure Vessel
                          </Link>
                          <Link to="/newpro/23">
                             Heat
                            Exchanger
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Rotary Kiln
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Dryer & Cooler
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Scrubber
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Transfer Car
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Tundish Car
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Water Seal Check Valve
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Laddle
                          </Link>
                          <Link to="/newpro/23">
                             Sand
                            Washing Plant
                          </Link>
                          <p className={`${subSubhbLink === "sub-for_Steel_Plant-min" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-for_Steel_Plant-min")}>{" "}Equipments for Steel Plan<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-for_Steel_Plant-min" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Equipment For Billet & Bloom Caster</Link>
                              <Link to='#'>Whell & Skid Mounted Crushing Plant</Link>
                              <Link to='#'>Equipment For Converter</Link>
                              <Link to='#'>Equipment For Blast Furnace</Link>
                            </div>
                          </div>

                          <p className={`${subSubhbLink === "sub-port_handeling-min" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-port_handeling-min")}>{" "}Port Handling Equipment<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-port_handeling-min" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Barge Unloader</Link>
                              <Link to='#'>Container Handling Crane</Link>
                              <Link to='#'>Bulk Handling Crane</Link>
                              <Link to='#'>Shipyard Crane</Link>
                            </div>
                          </div>

                          <p className={`${subSubhbLink === "sub-opencast_mining_equp-min" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-opencast_mining_equp-min")}>{" "}Opencast Mining Equipment<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-opencast_mining_equp-min" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Bucket Wheel Excavator</Link>
                              <Link to='#'>Crawler Mounted Spreader</Link>
                              <Link to='#'>Shiftable Conveyor</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hamburger-menu-div">
                <p className={`${hbLink === "hb_Mineral_Processing" ? "nav-menu-active" : ""}`} onClick={() => handleHBActiveLink("hb_Mineral_Processing")}> Mineral Processing{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                <div className={`hamburger-master-sub ${hbLink === "hb_Mineral_Processing" ? "show-menu" : ""}`}>
                  <div className={`hamburger-sub-menu `}>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Crushing_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Crushing_Equipments")}>Crushing Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Crushing_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Corn Crusher
                          </Link>
                          <p className={`${subSubhbLink === "sub-mine-Jaw_Crusher" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-mine-Jaw_Crusher")}>{" "}Jaw Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-mine-Jaw_Crusher" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Single Toggle</Link>
                              <a href='/newpro/6'>Double Toggle</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Screening_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Screening_Equipments")}>Screening Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Screening_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Circular Motion Vibrating Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Linear Motion Vibrating Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Flip Flop Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Wet Screen
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Screening Feeder
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Grinding_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Grinding_Equipments")}>Grinding Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Grinding_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Rod Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Ball Mill
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Vibrating Tube Mill
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Feeding_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Feeding_Equipments")}>Feeding Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Feeding_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Arpon Feeer
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Mechanical Vibratory Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Reciprocating Feeder
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Grizzly Feeder
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Material_Handeling_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Material_Handeling_Equipments")}>Material Handling Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Material_Handeling_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Belt Conveyer & Components
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Screw Conveyor
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Thickners" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Thickners")}>Thickners{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Thickners" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            High Rated Thickner
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Clarifiers
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Process_Plant_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Process_Plant_Equipments")}>Process Plant Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Process_Plant_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to="/newpro/23">
                            {" "}
                            Flotation Cell
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Pressure Filters
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Aggregate Plant
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Skid Plant
                          </Link>
                          <Link to="/newpro/23">
                            {" "}
                            Rotary Scrubber
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-min-Tailor_Build" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-min-Tailor_Build")}>Tailor Build{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-min-Tailor_Build" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <p className={`${subSubhbLink === "sub-for_Steel_Plant-mineral" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-for_Steel_Plant-mineral")}>{" "}Equipments for Steel Plan<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-for_Steel_Plant-mineral" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Whell & Skid Mounted Crushing Plant</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hamburger-menu-div">
                <p className={`${hbLink === "hb_Aggeregate" ? "nav-menu-active" : ""}`} onClick={() => handleHBActiveLink("hb_Aggeregate")}> Aggeregate{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                <div className={`hamburger-master-sub ${hbLink === "hb_Aggeregate" ? "show-menu" : ""}`}>
                  <div className={`hamburger-sub-menu `}>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-agg-Crushing_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-agg-Crushing_Equipments")}>Crushing Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-agg-Crushing_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='#'>Cone Crusher</Link>
                          <p className={`${subSubhbLink === "sub-Double_Roll" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-Double_Roll")}>{" "}Double Roll Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-Double_Roll" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to={'/newpro/18'}>Smooth Double Roll Crusher</Link>
                            </div>
                          </div>
                          <Link to='#'>Verticle Shaft Impactor</Link>
                          <p className={`${subSubhbLink === "sub-Jaw_Crusher" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-Jaw_Crusher")}>{" "}Jaw Crusher<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-Jaw_Crusher" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Single Toggle</Link>
                              <a href='/newpro/6'>Double Toggle</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-agg-Screening_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-agg-Screening_Equipments")}>Screening Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-agg-Screening_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='#'>Circular Motion Vibrating Screen</Link>
                          <Link to='#'>Wet Screen</Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-agg-Feeding_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-agg-Feeding_Equipments")}>Feeding Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-agg-Feeding_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to={'/newpro/17'}>Apron Feeder</Link>
                          <Link to='#'>Mechanical Vibratory Feeder</Link>
                          <Link to='#'>Reciprocating Feeder</Link>
                          <Link to='#'>Grizzly Feeder</Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-agg-Material_Handeling_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-agg-Material_Handeling_Equipments")}>Material Handling Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-agg-Material_Handeling_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='#'>Belt Conveyer & Components</Link>
                          <Link to='#'>Screw Conveyor</Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-agg-Process_Plant_Equipments" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-agg-Process_Plant_Equipments")}>Process Plant Equipments{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-agg-Process_Plant_Equipments" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='#'>Aggregate Plant</Link>
                          <Link to='#'>Skid Plant</Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-agg-Tailor_Build" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-agg-Tailor_Build")}>Tailor Build{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-agg-Tailor_Build" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='#'>Sand Washing Plant</Link>
                          <p className={`${subSubhbLink === "sub-for_Steel_Plant-agg" ? "nav-menu-active" : ""}`} onClick={() => handleSubSubHBActiveLink("sub-for_Steel_Plant-agg")}>{" "}Equipments for Steel Plan<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                          <div className={`hamburger-master-sub ${subSubhbLink === "sub-for_Steel_Plant-agg" ? "show-menu" : ""}`}>
                            <div className="hamburger-sub-menu">
                              <Link to='#'>Equipment For Billet & Bloom Caster</Link>
                              <Link to='#'>Whell & Skid Mounted Crushing Plant</Link>
                              <Link to='#'>Equipment For Converter</Link>
                              <Link to='#'>Equipment For Blast Furnace</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="hamburger-menu-div">
                <p className={`${hbLink === "hb_Insights" ? "nav-menu-active" : ""}`} onClick={() => handleHBActiveLink("hb_Insights")}> Insights{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                <div className={`hamburger-master-sub ${hbLink === "hb_Insights" ? "show-menu" : ""}`}>
                  <div className={`hamburger-sub-menu `}>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-newsroom" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-")}>Newsroom{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='/newsroom/company-announcement'>Company Announcement</Link>
                          <Link to='/newsroom/news-events'>News Event</Link>
                          <Link to="/newsroom/media-coverage">Media Coverage</Link>
                        </div>
                      </div>
                    </div>

                    <div className="hamburger-menu-div">
                      <p className={`${subhbLink === "sub-media" ? "nav-menu-active" : ""}`} onClick={() => handleSubHBActiveLink("sub-media")}>Media{" "}<i className="fa fa-caret-down" aria-hidden="true"></i></p>
                      <div className={`hamburger-master-sub ${subhbLink === "sub-media" ? "show-menu" : ""}`}>
                        <div className="hamburger-sub-menu">
                          <Link to='/media'>Image</Link>
                          <Link to='/media-video'>Video</Link>
                        </div>
                      </div>
                    </div>

                    <Link to="/blogs">Blog</Link>
                    <Link to="/downloads">Downloads</Link>

                  </div>
                </div>
              </div>

              <Link to="/contact-us">Contact Us</Link>
            </div>
          </div>
        </div>

        <div
          className={`backgroundBlur ${secondRibbon ? "showBackground" : ""}`}
          onClick={handleCloseRibbon}
        ></div>
        <div
          className={`second-ribbon ${secondRibbon ? "show-second-ribbon" : ""
            }`}
        >
          <div className="second-ribbon-close-button">
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={handleCloseRibbon}
            ></i>
          </div>

          <div className="ribbon-menu">
            <div className="title  ribbon-title bbefore">
              <span>Menu</span>
            </div>
            <div className="ribbon-menu-items">
              <div className="a-menu">
                <div className="nav-menu">
                  <Link onClick={() => setShowRibbonOf(true)}>
                    Our Offerings{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className="nav-menu">
                  <Link to={"/ribbon-page"}>Mining</Link>
                </div>
                <div className="nav-menu">
                  <Link to={"/ribbon-page"}>MIniral Processing</Link>
                </div>
                <div className="nav-menu">
                  <Link to={"/ribbon-page"}>Power</Link>
                </div>
                <div className="nav-menu">
                  <Link to={"/ribbon-page"}>Waste water management</Link>
                </div>
                <div className="nav-menu">
                  <Link to={"/ribbon-page"}>Services</Link>
                </div>
                <div className="nav-menu">
                  <Link to={"/ribbon-page"}>Spare Parts</Link>
                </div>
              </div>

              <div
                className={`ribbon-sub-menu ${showRibbonOf ? "rebbon-sub-menu-show" : ""
                  }`}
              >
                <div className="ribbon-top-sticky">
                  <p onClick={() => setShowRibbonOf(false)}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                  </p>
                  <div className="title bbefore">
                    <span>Our Offerings</span>
                  </div>
                </div>
                <p onClick={() => handleOfRibbonShow("Grinding_Equipments")}>
                  Grinding Equipments{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Screening_Equipments")}>
                  Screening Equipments{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Feeding_Equipments")}>
                  Feeding Equipments{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Crushing_Equipments")}>
                  Crushing Equipments{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Job_Shop")}>
                  Job Shop{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p
                  onClick={() => handleOfRibbonShow("Construction_Equipments")}
                >
                  Construction Equipments{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Steel_Plants")}>
                  Equipment for Steel Plants{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Process_Plant")}>
                  Process Plant Equipments{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Ash_Handling")}>
                  Ash Handling Equipment{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>
                <p onClick={() => handleOfRibbonShow("Material_Handling")}>
                  Material Handling Equipment{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </p>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Grinding_Equipments"
                    ? "of-ribbon-show"
                    : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <p onClick={() => setOfSubRibbon(null)}>
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                      Back
                    </p>
                    <div className="title bbefore">
                      <span>Grinding Equipments</span>
                    </div>
                    <Link to=""> Ball Mill</Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Screening_Equipments"
                    ? "of-ribbon-show"
                    : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-stikcy">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Screening Equipments</span>
                      </div>
                    </div>
                    <Link to="/newpro/23">Circular Motion Vibrating Screen</Link>
                    <Link to="/newpro/23">Linear Motion Vibrating Screen</Link>
                    <Link to={'/newpro/11'}>Roller Screen</Link>
                    <Link to="/newpro/23">Live Roll Grizzly</Link>
                    <Link to="/newpro/23">High Particle Acceleration Screen</Link>
                    <Link to="/newpro/23">Banana Screen</Link>
                    <Link to="/newpro/23">Screening Feeder</Link>
                    <Link to="/newpro/23">Rod Mill</Link>
                    <Link to="/newpro/23">Vibrating Tube Mill</Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Feeding_Equipments" ? "of-ribbon-show" : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Feeding Equipments</span>
                      </div>
                    </div>
                    <Link to={'/newpro/17'}>Apron Feeder</Link>
                    <Link to="/newpro/23">Reciprocating Feeder</Link>
                    <Link to="/newpro/23">Vibratory Feeder</Link>
                    <Link to="/newpro/23">Disc Feeder</Link>
                    <Link to="/newpro/23">Grizzly Feeder</Link>
                    <Link to="/newpro/23">Belt Feeder</Link>
                    <Link to="/newpro/23">Paddle Feeder</Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Crushing_Equipments"
                    ? "of-ribbon-show"
                    : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Crushing Equipments</span>
                      </div>
                    </div>
                    <Link to={'/newpro/20'}>Single Roll Crusher</Link>
                    <Link to={'/newpro/18'}>Smooth Double Roll Crusher</Link>
                    <Link to="/newpro/23">Triple Roll Crusher</Link>
                    <Link to={'/newpro/12'}>Heavy duty impactor</Link>
                    <Link to="/newpro/23">Corrugated Double Roll Crusher</Link>
                    <Link to="/newpro/23">Double Roll Crusher</Link>
                    <Link to="/newpro/23">Triple Roll Crusher</Link>
                    <Link to="/newpro/23">General Duty Impactor (EK Series)</Link>
                    <Link to="/newpro/23">Swing Hammer Reversible Impactor</Link>
                    <Link to="/newpro/23">Reversible Hammer Mill</Link>
                    <Link to="/newpro/23">One Way Hammer Mill</Link>
                    <Link to={'/newpro/22'}>Hammer Mill</Link>
                    <Link to={'/newpro/21'}>Ring Granulator</Link>
                    <Link to="/newpro/23">Single Toggle Jaw Crusher</Link>
                    <Link to={'/newpro/6'}>Double Toggle Jaw Crusher</Link>
                    <Link to="/newpro/23">Cone Crusher</Link>
                    <Link to="/newpro/23">Coke Cutter</Link>
                    <Link to={'/newpro/10'}>Rotary Breaker</Link>
                    <Link to={'/newpro/13'}>Chain Mill</Link>
                    <Link to="/newpro/23">Lump Loosner</Link>
                    <Link to="/newpro/23">Single Roll Lump Breaker</Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Job_Shop" ? "of-ribbon-show" : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Job Shop</span>
                      </div>
                    </div>

                    <Link to="/newpro/23">Pressure Vessel</Link>
                    <Link to="/newpro/23">Heat Exchanger</Link>
                    <Link to="/newpro/23">Rotary Kiln</Link>
                    <Link to="/newpro/23">Dryer & Cooler</Link>
                    <Link to="/newpro/23">Scrubber</Link>
                    <Link to="/newpro/23">Other Equipment</Link>
                    <Link to="/newpro/23">Barge Unloader - Port Handling Equipment</Link>
                    <Link to="/newpro/23">
                      Container Handling Crane - Port Handling Equipment
                    </Link>
                    <Link to="/newpro/23">
                      Bulk Handling Crane - Port Handling Equipment
                    </Link>
                    <Link to="/newpro/23">Shipyard Crane - Port Handling Equipment</Link>
                    <Link to="/newpro/23">
                      Bucket Wheel Excavator - Opencast Mining Equipment
                    </Link>
                    <Link to="/newpro/23">
                      Crawler Mounted Spreader - Opencast Mining Equipment
                    </Link>
                    <Link to="/newpro/23">
                      Shiftable Conveyor - Opencast Mining Equipment
                    </Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Construction_Equipments"
                    ? "of-ribbon-show"
                    : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Construction Equipments</span>
                      </div>
                    </div>
                    <Link to="/newpro/23">
                      Wheel &
                      Skid Mounted Aggragate Crushing & Screenning Plant
                    </Link>
                    <Link to="/newpro/23">
                      Sand
                      Washing Plant
                    </Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Steel_Plants" ? "of-ribbon-show" : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Equipment for Steel Plants</span>
                      </div>
                    </div>
                    <Link to="/newpro/23">
                      {" "}
                      Equipment For Billet & Bloom Caster
                    </Link>
                    <Link to="/newpro/23">
                       Wheel &
                      Skid Moounted Crushing Plant
                    </Link>
                    <Link to="/newpro/23">
                      {" "}
                      Equipment For Converter
                    </Link>
                    <Link to="/newpro/23">
                      {" "}
                      Equipment For Blast Furnace
                    </Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Process_Plant" ? "of-ribbon-show" : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Process Plant Equipments</span>
                      </div>
                    </div>

                    <Link to="/newpro/23">Flotation Cell</Link>
                    <Link to="/newpro/23">Column Flotation Cell</Link>
                    <Link to="/newpro/23">Thickeners</Link>
                    <Link to="/newpro/23">Cetrifugal Grvity Concentrator</Link>
                    <Link to="/newpro/23">Magnetic Separtor</Link>
                    <Link to="/newpro/23">Filters</Link>
                    <Link to="/newpro/23">Rotary Scrubber</Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Ash_Handling" ? "of-ribbon-show" : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Ash Handling Equipments</span>
                      </div>
                    </div>
                    <Link to="/newpro/23">Transpoter Vessel</Link>
                    <Link to="/newpro/23">Air Receiver</Link>
                    <Link to="/newpro/23">Clinker Crusher</Link>
                    <Link to="/newpro/23">Mixer</Link>
                    <Link to="/newpro/23">Scrapper Chain Conveyor Bag Filter</Link>
                  </div>
                </div>

                <div
                  className={`ribbon-sub-menu-sub-menu ${ofSubRibbon === "Material_Handling" ? "of-ribbon-show" : ""
                    }`}
                >
                  <div className="of-ribbon-menu">
                    <div className="ribbon-top-sticky">
                      <p onClick={() => setOfSubRibbon(null)}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </p>
                      <div className="title bbefore">
                        <span>Material Handling Equipment</span>
                      </div>
                    </div>
                    <Link to="/newpro/23">Wagon Tippler</Link>
                    <Link to="/newpro/23">Side Arm Charger</Link>
                    <Link to="/newpro/23">Stacker Reclaimer</Link>
                    <Link to="/newpro/23">Bucket Elevator</Link>
                    <Link to="/newpro/23">Tripper</Link>
                    <Link to="/newpro/23">Belt Conveyer & Components</Link>
                    <Link to="/newpro/23">Screw Conveyor</Link>
                    <Link to="/newpro/23">Gravity Roller Conveyor</Link>
                    <Link to="/newpro/23">Powered Roller Conveyor</Link>
                    <Link to="/newpro/23">Eot Crane</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`searchBar ${showSearch ? "searchBarShow" : ""}`}>
        <div className="search-input">
          <div className="border-bottom">
            <input
              type="text"
              placeholder="Search..."
              ref={searchRef}
              value={searchInput}
              onChange={handleSearch}
            />
          </div>
          <div className="search-search" onClick={handleCloseSearch}>
            Close
          </div>
        </div>
        <div className="searched-results">
          {searchedProducts.length !== 0
            ? searchedProducts.slice(0, 9).map((item) => (
              <Link to={`/newpro/${item.id}`} className="searched-results-card" key={item.id}>
                <img src={`https://tega.codtrees.com/public/storage/product/${item.product_image}`} alt="product" />
                <Link to={`/newpro/${item.id}`}>
                  {item.product_name}
                </Link>
              </Link>
            ))
            : searchInput.length >= 4
              ? "No Product found!"
              : ""}
        </div>
      </div>
    </header>
  );
};
