import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container,Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
const OurClients=()=>{
    const bannerData={
        imgurl:'images/client_banner.jpg',
       
    };
    return(
    <>
    <Helmet>
<title>Our Clients | Tega Mcnally</title>
  <meta name="title" content="Our Clients  | Tega Mcnally"/>
<meta name="description" content="Meet Our Clients"/>

</Helmet>
            <InternalBanner{...bannerData}/>
<div className="page-content">
    <Container>
    <h2 className="bbefore"><span>Our Clients</span></h2>
    <Row>
    <Col md={3} className="text-center mb-4">
        <img src="/clientlogo/amns.png" className="img-fluid" alt="client"></img>
    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) aditya birla.png" className="img-fluid" alt="client"></img>
    </Col>
   
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) cesc.png" className="img-fluid" alt="client"></img>

    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) danieli.png" className="img-fluid" alt="client"></img>

    </Col>
    </Row>


    <Row>
    <Col md={3} className="text-center mb-4">
        <img src="/clientlogo/download (2) essar.png" className="img-fluid" alt="client"></img>
    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) isgeg.png" className="img-fluid" alt="client"></img>
    </Col>
   
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) jsw.png" className="img-fluid" alt="client"></img>

    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) nalco.png" className="img-fluid" alt="client"></img>

    </Col>
    </Row>

    <Row>
    <Col md={3} className="text-center mb-4">
        <img src="/clientlogo/download (2) ntpc.png" className="img-fluid" alt="client"></img>
    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) sail.png" className="img-fluid" alt="client"></img>
    </Col>
   
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) tata.png" className="img-fluid" alt="client"></img>

    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2) ucil.png" className="img-fluid" alt="client"></img>

    </Col>
    </Row>

    <Row>
    <Col md={3} className="text-center mb-4">
        <img src="/clientlogo/download (2)bhel.png" className="img-fluid" alt="client"></img>
    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2)coal india.png" className="img-fluid" alt="client"></img>
    </Col>
   
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download (2)hindustan zinc.png" className="img-fluid" alt="client"></img>

    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download dvc.jpeg" className="img-fluid" alt="client"></img>

    </Col>
    </Row>

    <Row>
    <Col md={3} className="text-center mb-4">
        <img src="/clientlogo/download hindustan copper.jpeg" className="img-fluid" alt="client"></img>
    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download hutti.jpeg" className="img-fluid" alt="client"></img>
    </Col>
   
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download konkola.jpeg" className="img-fluid" alt="client"></img>

    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download l and t.jpeg" className="img-fluid" alt="client"></img>

    </Col>
    </Row>

    <Row>
    <Col md={3} className="text-center mb-4">
        <img src="/clientlogo/smsgroup.png" className="img-fluid" alt="client"></img>
    </Col>
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/download vedanta.jpeg" className="img-fluid" alt="client"></img>
    </Col>
   
    <Col md={3} className="text-center mb-4">
    <img src="/clientlogo/images rungta.png" className="img-fluid" alt="client"></img>

    </Col>
    <Col md={3} className="text-center mb-4">

    </Col>
    </Row>
    </Container>
</div>


    
    </>
    );
}

export default OurClients;