import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaFacebook,
  FaEnvelope,
  FaMobile, FaAngleRight,
  FaLinkedin
} from "react-icons/fa";

import "../../css/footer.css";


const Footernew = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer>

        <Row className="row-footer">
        <Col md={4}>
            <div className="heading">
              <img
                src="/images/tega-TMML-logo2.png"
                width="300px" height="auto"
                alt="footer"
              ></img>
              {/* <h3>About Us</h3> */}
              {/* <p>Tega McNally Minerals Limited stands as a foremost manufacturer specializing in advanced equipment for crushing, screening, feeding, grinding, and mineral processing.  <Link to='/about'>Read More...</Link></p> */}
            </div>
          </Col>
          <Col md={1}></Col>
          
          <Col md={4}>
            <h4 style={{ color: "#F6911E", marginLeft: "100px" }}>Quick Links</h4>
            <Row>
              <Col md={6} xs={6}>
                <ul className="twocol">
                  <li><FaAngleRight /> <Link to='/'>Home</Link></li>
                  <li><FaAngleRight /><Link to='/about'>About Us</Link></li>
                  <li><FaAngleRight /><Link to='/downloads'>Download</Link></li>

                  {/* <li><FaAngleRight /><Link to='/our-offerings'>Our Offerings</Link></li> */}
                  
                </ul>
              </Col>
              <Col md={6} xs={6}>
<ul className="twocol">
                  
                  <li><FaAngleRight /> <Link to='/career/open-positions'>Career</Link></li>
                  <li><FaAngleRight /> <Link to='/contact-us'>Contact Us</Link></li>
                  <li><FaAngleRight /><Link to='/blogs'>  Blogs</Link></li>

                </ul>
              </Col>
            </Row>

          </Col>

          
          {/* <Col md={1}></Col> */}
        <Col md={2} >
            <h4 style={{ color: "#F6911E" }}>Contact Details</h4>
            <div className="socicon d-flex justify-content-start mb-2">
              <Link
                to="https://www.facebook.com/tegamcnallymineralslimited"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
                style ={{"marginRight": 20}}
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://www.linkedin.com/company/101050903/admin/feed/posts/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
                style ={{"marginRight": 20}}
              >  <FaLinkedin /> 
              </Link>
              {/* <Link
                to="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <FaYoutube />
              </Link> */}
              <Link
                to="https://www.instagram.com/tegamcnallymineralslimited/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
                style ={{"marginRight": 20}}
              >
                <FaInstagram />
              </Link>
            </div>
            {/* <p>
              <FaEnvelope />{" "}
              <Link to="mailto:info@tegaminerals.com">
                info@tegaminerals.com
              </Link>
            </p>
            <p>
              <FaMobile />  <Link to="tel: +91 9163334602">+91 9163334602</Link>
            </p>
            <p>
              Registered Office - 16th floor, Tower – II, Godrej Water Side, Sec
              - V Salt Lake City, Kolkata – 700 091, West Bengal{" "}
            </p> */}

          </Col>

          

        </Row>


      </footer>
      <div className="text-center copyright">
        <p style={{"marginLeft": "140px"}}>	&#169; {currentYear}. All rights reserved. </p>
      </div>
    </>
  );
}
export default Footernew;