import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container } from "react-bootstrap";

const Organisation=()=>{
    const bannerData={
        imgurl:'images/industries.jpg',
        title:"Our Organisation",
        description:"This is Organisation page",
    };
    return(
    <div>
            <InternalBanner{...bannerData}/>
<div className="page-content">
<Container>
    <p>Coming soon.....</p>
    </Container>
    
    </div>
    </div>
    );
}

export default Organisation;