import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
// import img1 from '../../../public/images/our-facilities/image.png'
// import img2 from '../../../public/images/our-facilities/image1.png'
// import img3 from '../../../public/images/our-facilities/image2.png'
// import feeding from '../../../public/images/our-facilities/feeding.png'
// import process_plant from '../../../public/images/our-facilities/process_plant.png'
// import construction from '../../../public/images/our-facilities/construction_eqp.png'



const ProductsComponent = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [products, setProduct] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSlidesPerView(4);
      } else {
        setSlidesPerView(1);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);
  const product = async () => {
    try {
      const response = await axios.get(`https://tega.codtrees.com/api/admin/addproduct`);
      setProduct(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  const sanitizedHTML = DOMPurify.sanitize(product.product_description);

  useEffect(() => {
    product();
  }, []);

  return (
    <Container className="product_carousel">
      <h3 className="bbefore">
        <span>Our Products</span>
      </h3>
      <Row xs={4} md={2} lg={4} className="g-4">
      <Swiper
          spaceBetween={50}
          slidesPerView={slidesPerView}
          loop={true}
          navigation={true}
          modules={[Navigation, Autoplay, Pagination]}
          // pagination={{ clickable: true,
          //   dynamicBullets: true,}}
          >
        {/* {products.map((product, index) => (
        ))} */}

            
            
            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/APRONFEEDER.png' />
                  
                  <Card.Body className="product-body">
                    <h4>Apron Feeder</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
              <Card.Img className="offering-img" variant="top" src="/images/centrifuge.png"/>
                  
                  <Card.Body className="product-body">
                    <h4>Centrifuge</h4>
                    
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link to="/newpro/4" className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>
            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/grindingmill.jpg' />
                  
                  <Card.Body className="product-body">
                    <h4>Grinding Mill</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link  className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/RINGGRANULATOR.PNG' />
                  
                  <Card.Body className="product-body">
                    <h4>Ring Granulator</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/CIRCULARMOTIONVIBRATINGSCREENS.png' />
                  
                  <Card.Body className="product-body">
                    <h4>Circular Motion Screen</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/LINEARMOTIONSCREEN.PNG' />
                  
                  <Card.Body className="product-body">
                    <h4>Linear Motion Screen</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link  className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/Double-Roll-Crusher-1.jpg' />
                  
                  <Card.Body className="product-body">
                    <h4>Double Roll Crusher</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} /> */}
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

            

            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/6.png' />
                  
                  <Card.Body className="product-body">
                    <h4>Jaw Crusher</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link  className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>


            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/SINGLEROLLCRUSHER.PNG' />
                  
                  <Card.Body className="product-body">
                    <h4>Single Roll Crusher</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>            
            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/SMOOTHROLLCRUSHER.png' />
                  
                  <Card.Body className="product-body">
                    <h4>Smooth Roll Crusher</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>
            <SwiperSlide>
              <Col>
                <Card className="product-card shadow">
                  <div className="image-overlay"></div>
                  {/* <Card.Img variant="top" src={`${apiUrl}/public/Assets/Image/products/${product.product_image}`} /> */}
                  <Card.Img variant="top" src='/images/our-facilities/REVERSIBLEHAMMERMILL.JPG' />
                  
                  <Card.Body className="product-body">
                    <h4>Reversible Hammer Mill
</h4>
                    {/* <p className="mb-0">{product.product_description}</p> */}
                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: product.product_description }} /> */}
                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    <div className="seemore text-center">
                    <Link className="see-more-button">
                      See More
                    </Link>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>

           
           

           

        </Swiper>
      </Row>
    </Container>
  );
};

export default ProductsComponent;