import React,{useState} from 'react';
import { Container, Row, Col,Nav  } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

const Featured = () => {

  const [activeTab, setActiveTab] = useState('Section1');
  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const [activeTitle, setActiveTitle] = useState('title1');
  const handleClick = (title) => {
    setActiveTitle(title);
  };

  return (
    <section>
      <Container className='limited-material feat'>
          <h3 className="bbefore">
            <span>Our Offerings</span>
          </h3>
          <div className="core-values">              
            <dl class="collapsible-tabs-vertical">
            <dt className={`collapsible-title btn ${activeTitle === 'title2' ? 'is-active' : ''}`} onClick={() => handleClick('title2')}>Customized Solutions<span></span></dt>
            <dd class="collapsible-content">
            <ul>
    <li>Customized solutions for unique project needs</li>
    <li>Tailored services from consultation to installation</li>
    <li>Close collaboration for seamless execution and maximum value</li>
    <li>Comprehensive after-sales support and maintenance</li>
    <li>Superior products with a commitment to client success</li>
</ul>

            </dd>
            <dt className={`collapsible-title btn ${activeTitle === 'title3' ? 'is-active' : ''}`} onClick={() => handleClick('title3')}>Comprehensive Service Solutions<span></span></dt>
            <dd class="collapsible-content">
            <ul>
    <li>Four world-class manufacturing facilities: Kumardhubi, Asansol, Vadodara, Bangalore</li>
    <li>Advanced machinery operated by skilled professionals</li>
    <li>Expertise in fabricating coal washeries, mineral beneficiation plants, and material handling systems</li>
    <li>Reliable products with comprehensive services: installation, commissioning, and health checkups</li>
    <li>Consistent excellence in delivering precision equipment for core industries</li>
</ul>
            </dd>
            <dt className={`collapsible-title btn ${activeTitle === 'title1' ? 'is-active' : ''}`} onClick={() => handleClick('title1')}>Industry Expertise and Innovation<span></span></dt>
            <dd class="collapsible-content">
              
            <ul>
    <li>Over 60 years of industry expertise and innovation</li>
    <li>Serving mining, power, cement, steel, non-ferrous, oil & gas, and construction sectors</li>
    <li>State-of-the-art equipment and advanced technologies</li>
    <li>Comprehensive, sustainable solutions</li>
    <li>Deep understanding of industry challenges</li>
</ul>

            </dd>

           

            

            </dl>
          </div>
        </Container>
    </section>
  );
};

export default Featured;
