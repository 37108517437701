import React,{useState, useEffect} from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import '../../css/news.css'
const NewsComp = () => {
    const [news, setNews] = useState([]);
    const bannerData={
        imgurl:'/images/industries.jpg',
        // title:"Newsroom",
        // description:"This is Blogs page",
    };
   
    const apiUrl = process.env.REACT_APP_API_URL;
    const newsnew = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/admin/addnews`);
          setNews(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      newsnew();
    }, []);

    function truncateDescription(description) {
      // Decode HTML entities
      description = decodeURIComponent(description);
    
      // Split the description into words
      const words = description.split(' ');
    
      // Only take the first 30 words
      const truncatedWords = words.slice(0, 20);
    
      // Join the words back into a string
      return truncatedWords.join(' ');
    }

    const dateFormat = (inputDate) => {
      const date = new Date(inputDate);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-GB', options);
      return formattedDate;
    }

    const [category, setCategory] = useState('News_Events');

    return(
        <>
        <InternalBanner{...bannerData}/>
        <Container fluid>
        <div className="u_mb-60">
          <div className="row js-pushpin-parent">
            <div className="col-md-12">
              <article className="post">
                <div className="row js-pushpin-parent">
                  <div className="col-sm-3">
                  <div className="tile blog-categories news-sidebar">
                        <div className="blog-tags text-left">
                          {/* <ul className="pdflist shadow">
                          
                          </ul> */}
                          <Link to='/newsroom/company-announcement' className="radio-body" >
                            
                            <input
                              type="radio"
                              id="Company_Announcements"
                              name="fav_language"
                              defaultValue="CSS"
                              checked={category === 'Company_Announcements' ? true: false}
                            />
                            <label htmlFor="Company_Announcements"  ><i class="fa fa-angle-double-right" aria-hidden="true"></i> Company Announcements</label>
                            <br />
                          </Link>
                          <Link to="/newsroom/news-events" className="radio-body" >
                            <input
                              type="radio"
                              id="news-events"
                              name="fav_language"
                              defaultValue="CSS"
                              checked={category === 'News_Events' ? true: false}
                            />
                            <label htmlFor="news-events" ><i class="fa fa-angle-double-right" aria-hidden="true"></i> News & Events</label>
                            <br />
                          </Link>
                          <Link to='/newsroom/media-coverage' className="radio-body" >

                            <input
                              type="radio"
                              id="Media_Coverage"
                              name="fav_language"
                              defaultValue="JavaScript"
                              checked={category === 'Media_Coverage' ?true : false}
                            />
                            <label htmlFor="Media_Coverage"><i class="fa fa-angle-double-right" aria-hidden="true" ></i> Media Coverage</label>
                            </Link>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-6 all-news">
                    <div className="single-news">
                      <header className="post__header post-header">
                        <h2 className="post-header__title">
                          <p>08, April 2024</p>
                          <p>Industry</p>
                        </h2>
                        <h2 className="post-header__title">
                        Tega McNally Minerals: Trusted  Solutions for the Mining & Mineral Processing Industry
                        </h2>
                      </header>
                      <div className=" post-content">
                        <p>
                        In the competitive and evolving mining and mineral processing industry, companies must rely on trusted partners to navigate challenges and optimize operations. Tega McNally Minerals Limited has established itself as a reliable name, offering comprehensive solutions that cater to the varied needs of the sector. This blog explores the capabilities of Tega McNally Minerals and how the company has solidified its reputation as a dependable partner in the industry.

                        </p>
                      </div>
                    </div>
                    <div className="single-news">
                      <header className="post__header post-header">
                        <h2 className="post-header__title">
                          <p>08, April 2024</p>
                          <p>Industry</p>
                        </h2>
                        <h2 className="post-header__title">
                        Customized Equipment Solutions for the Mining & Mineral Processing Industry
                        </h2>
                      </header>
                      <div className=" post-content">
                        <p>
                        In the mining and mineral processing industry, operational efficiency and productivity are crucial. Tega McNally Minerals Limited has positioned itself as a provider of tailored equipment solutions that meet the specific needs of the sector. This blog explores how Tega McNally Minerals addresses the challenges of mining operations through its customized approach and specialized offerings.

                        </p>
                      </div>
                    </div>
                    <div className="single-news">
                      <header className="post__header post-header">
                        <h2 className="post-header__title">
                          <p>08, April 2024</p>
                          <p>Industry</p>
                        </h2>
                        <h2 className="post-header__title">
                        Optimizing Mining & Mineral Processing with Reliable Equipment Solutions by Tega McNally Minerals
                        </h2>
                      </header>
                      <div className=" post-content">
                        <p>
                        In the mining and mineral processing industries, operational efficiency and reliability are critical factors for sustained success. Tega McNally Minerals Limited has established itself as a trusted provider of equipment solutions designed to enhance these aspects. This blog explores how Tega McNally Minerals contributes to optimizing mining and mineral processing operations through its reliable equipment solutions.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="tile blog-categories news-sidebar">
                      <div className="news-sidebar-side">
                        <div className="tile__title">News</div>
                        <ul className="pdflist shadow">
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> News 1</p>
                          </li>
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> News 2</p>
                          </li>
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> News 3</p>
                          </li>
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> News 4</p>
                          </li>
                        </ul>
                      </div>
                      <div className="news-sidebar-side">
                        <div className="tile__title">Events</div>
                        <ul className="pdflist shadow">
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Event 1</p>
                          </li>
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Event 2</p>
                          </li>
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Event 3</p>
                          </li>
                          <li>
                            <p><i class="fa fa-angle-double-right" aria-hidden="true"></i> Event 4</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        </Container>
        </>
    );
}   

export default NewsComp;