import React, { useState, useEffect } from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/blogs.css";
import { Helmet } from "react-helmet";
const Blogs = () => {

  const location = useLocation()  
  const bannerData = {
    imgurl: "/images/industries.jpg",
    // title: "Our Blogs",
    
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const [blogData, setBlogData] = useState([]);
  const [blogs, setBlog] = useState([]);
  const [blogCategory, setBlogCategory] = useState([]);
  useEffect(() => {
    axios
      .get(`https://tega.codtrees.com/api/blogcat_getdata`)
      .then((res) => {
        setBlogCategory(res.data.content);
        console.log(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const {cate_type=""} = location.state || '';

  const [catName, setCatName] = useState(cate_type.length > 1 ? cate_type : ' ');
  const [activeCategory, setActiveCategory] = useState(cate_type.length > 1 ? cate_type : ' ');

  function handleClick(cat_name) {
    console.log(cat_name, "is selected");
    setCatName(cat_name);
    setActiveCategory(cat_name);
  }


  const getCategoryClass = (cat_name) => {
    return cat_name === activeCategory ? "active-category" : "";
  };


  useEffect(() => {
    axios.post(`https://tega.codtrees.com/api/admin/blogcategory/category`, { "category": catName })
      .then(res => {
        setBlogData(res.data.blogs)
        setBlog(res.data.blogs)
        console.log(res.data);
      }).catch(error => {
        console.log(error);
      })
  }, [catName])


  const handleFilterBlog = (id) => {
    if (id === null) {
      setBlog(blogData)
    } else {
      const filtered = blogData.filter(obj => obj.category === null ? null : obj.category.includes(id))
      if (filtered.length > 0) {
        setBlog(filtered)
      } else {
        setBlog(blogData)
      }

      console.log(filtered)
    }

    console.log(id)
  }


  return (
    <>
        <Helmet>
    <title>Blogs | Tega Mcnally</title>
    <meta name="title" content="Blogs | Tega Mcnally" />
    <meta name="description" content="Blogs" />
</Helmet>
      <InternalBanner {...bannerData} />
      <Container className="blog-container" fluid>
        <div>
          <div className="row js-pushpin-parent">
            <div className="col-sm-3">
              <div className="tile blog-categories blog-sidebar">
                {/* <div className="blog-tags "> */}
                <ul className="pdflistab shadow">
                  <div className="tile__title">Categories</div>

                  <li className={`radio-body ${getCategoryClass("")}`}>
                      <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                      &nbsp;{" "}
                      <input
                        type="radio"
                        id="all_cate"
                        name="fav_language"
                        defaultValue="CSS"
                        onChange={() => handleFilterBlog(null)}
                        defaultChecked
                      />
                      &nbsp; <label htmlFor="all_cate" onClick={() => handleClick("")}>All Blogs</label>
                      <br />
                  </li>
                  {
                    blogCategory.map((cate, index)=>(
                      <li key={index} className={`radio-body ${getCategoryClass(cate.category_name)}`}>
                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        &nbsp;{" "}
                        <input
                          type="radio"
                          id={`${cate.id}`}
                          name="fav_language"
                          defaultValue="CSS"
                          onChange={()=>handleFilterBlog(cate.id)}
                          onClick={() => handleClick(cate.category_name)}
                          
                        />
                        &nbsp; <label htmlFor={`${cate.id}`}>{cate.category_name}</label>
                        <br />
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="col-sm-6 all-blogs" >
              {
                blogs ? blogs.map((item, index) => (
                  <div className="single-blog-card mb-4" key={index}>
                    <div className="post__content post-content">
                      <div className="post-header__thumbnail ">
                        <Link to={`/blog/${item.slug}`}>
                          <img src={`https://tega.codtrees.com/public/Assets/Image/blog/${item.blog_image}`} />
                        </Link>
                      </div>
                      <div className="the-other-half">
                        <header className="post__header post-header">
                          <h2 className="post-header__title">
                            <Link to={`/blog/${item.slug}`}>
                              {" "}
                              {item.blog_title}
                            </Link>
                          </h2>
                        </header>
                        <p className="mb-0 limited-lines" dangerouslySetInnerHTML={{ __html: item.blog_description }} />
                        <Link className="u-tar post__read-more" Link to={`/blog/${item.slug}`}>
                          <span>Read more</span>
                          {/* <i class="fa fa-arrow-right" aria-hidden="true"></i> */}
                        </Link>
                      </div>
                    </div>
                  </div>
                )) : ""
              }
            </div>
            <div className="col-md-3">
              <aside className="blog-sidebar js-pushpin-target">
                <div className="tile blog-archive">
                  <div className="tile__title">Latest Blogs</div>
                  <ul className="blog-archive__years text-center">
                    {
                      blogData.slice(0, 2).map((item, index) => (
                        <li className="clearfix" key={index}>
                          <Link to={`/blog/${item.slug}`}>
                            <img src={`https://tega.codtrees.com/public/Assets/Image/blog/${item.blog_image}`} />
                            <p>{item.blog_title}</p>
                          </Link>
                        </li>

                      ))
                    }
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Blogs;
