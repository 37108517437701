import React, { useState, useEffect } from "react";
// import InternalBanner from "../components/global/InternalBanner";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import "../css/about.css";
import { FaBuilding, FaHouseUser, FaLandmark, FaRecycle, FaClock, FaDesktop, FaPencilRuler, FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import StatsComponent from "../components/homepage/StatsComponent";
import "swiper/css/navigation";
import "swiper/css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import CarouselComponent from "../components/homepage/CarouselComponent";
import '../css/aboutus_team.css';
import Timeline from '../pages/aboutchild/Timeline';

import InternalBanner from "../components/global/InternalBanner";

import image2 from '../images/Picture2.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const AboutComponent = () => {
    const iconStyle = { fontSize: '20px', marginRight: '10px' };
    const textStyle = { fontWeight: 'bold', fontSize: '20px' };

        useEffect(() => {
            document.title = "About | Tega Mcnally";
          }, []);

    const [slidesPerView, setSlidesPerView] = useState(1);
    const [slidesPerViewclient, setSlidesPerViewclient] = useState(1);
    useEffect(() => {
        const handleResizeclient = () => {
            if (window.innerWidth >= 768) {
                setSlidesPerViewclient(5);
            } else {
                setSlidesPerViewclient(1);
            }
        };

        

        handleResizeclient(); // Set initial value
        window.addEventListener('resize', handleResizeclient);

        return () => {
            window.removeEventListener('resize', handleResizeclient);
        };
    }, []);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setSlidesPerView(3);
            } else {
                setSlidesPerView(1);
            }
        };

        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [activeTitle, setActiveTitle] = useState('title1');

    const handleClick = (title) => {
        setActiveTitle(title);
    };

    const bannerData={
        imgurl:'/factories/about_banner_123.jpg',
        // title:"About TMML",
    };

    return (
        <>
            <HelmetProvider>

<Helmet>
<title>About | Tega Mcnally</title>
  <meta name="title" content="About | Tega Mcnally"/>
<meta name="description" content="Tega McNally Minerals (TMML) specializes in manufacturing crushing, grinding, material handling, and mineral processing equipment, serving core industries like iron ore, coal, steel, zinc, copper, limestone, and rare earths for over six decades."/>

</Helmet>
</HelmetProvider>
            <InternalBanner{...bannerData}/>

            <Container className="aboutc">
                <br />
                <p>Tega McNally Minerals (TMML), specializes in manufacturing crushing, grinding, material handling, and mineral processing equipment. With a track record spanning over six decades, TMML has provided reliable solutions to core sector industries such as iron ore, coal, steel, zinc, copper, limestone, rare earths, and aggregates.</p>
                <ul>
    <li>Product Range: TMML offers a diverse range of equipment tailored to industry-specific requirements, including crushers (jaw, roll, impactors), screens (linear, circular, roller, flip-flop), ball and rod mills, feeders (apron, grizzly, reciprocating), and mineral beneficiation equipment (slurry pumps, thickeners, flotation cells, filter presses, scrubbers).</li>
    <li>Construction Sector: Additionally, TMML serves the construction sector with products like mobile crushing and screening plants, and skid-mounted crushing plants.</li>
</ul>
<br/>
<p>TMML operates from four state-of-the-art manufacturing units strategically located in Kumardhubi, Asansol, Bengaluru, and Vadodara. These facilities are equipped with advanced machinery and operated by skilled professionals, ensuring consistent excellence in fabrication.</p>

<ul><li>Sales and Service Network: Supported by a comprehensive sales and service network across India, TMML provides robust customer support from installation to after-sales services, including maintenance and health checkups.</li></ul>
<p>As a part of Tega Industries, TMML benefits from Tega's global leadership in mineral beneficiation, mining, and bulk solid handling industries. Tega's "Partnerships in Practice" philosophy emphasizes empowering professionals to deliver unmatched technical and economic solutions, solidifying its position as a significant player globally.</p>

<ul><li>Global Presence: TMML has installed equipment across diverse regions including India, Russia, Kenya, Mozambique, Zambia, South Africa, Indonesia, UAE, Oman, and Saudi Arabia, showcasing its extensive global reach and capabilities.</li></ul>
<br/>
<p>Looking forward, TMML aims to enhance its footprint in the mining and mineral beneficiation sectors, leveraging its synergistic product portfolio to strengthen Tega's brand as a leading global solutions provider.</p>

<p>For more information, please visit <Link to="https://www.tegaindustries.com/" target="_blank">Tega Industries</Link>.</p>
            </Container>

        </>
    );
}

export default AboutComponent;