import React, { useEffect, useState, useRef } from "react";

import axios from "axios";
import {
  Row,

  Container,

  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
const Download = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [brochures, setBrochure] = useState([]);

  const brochure = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/addbrochure`);
      setBrochure(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {

    brochure();

  }, []);
  return (
    <Container>
      <h3 className="bbefore"><span>Downloads</span></h3>
      <Row>
        {brochures.map((brochure, index) => (
          <Col md={3} key={index}> <div className="case-study-box-simple" style={{ "paddingBottom": "50px" }}>
            <Link to={`${apiUrl}/public/Assets/File/brochure/${brochure.brochure_file}`} target="_blank">
              <div className="content-overlay" />
              <img
                src={`${apiUrl}/public/Assets/Image/brochure/${brochure.brochure_thumbnail}`}
                className="content-image entered"
                alt="Case Study"
                style={{ "height": "310px" }}
              />

              <div className="content-details">
                <h4 className="case-study-gaping">{brochure.brochure_title}</h4>
              </div>

              <div className="show-on-hover">
                <h4>Download <i className="fa fa-download" aria-hidden="true"></i></h4>
              </div>

            </Link>
          </div></Col>
        ))}
      </Row>
      <div className="text-center mt-4">
        <Link to="/downloads" className="btn-c">View More &nbsp;<i class="fas fa-long-arrow-alt-right"></i></Link>
      </div>
    </Container>
  )

}

export default Download;