import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';



function SingleWhite(props) {
    const [whitepaper, setWhitepaper] = useState([]);
    const { slug } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        let isMounted = true;

        axios.get(`${apiUrl}/api/admin/whitepaper/${slug}`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setWhitepaper(res.data.whitepaper);
                }
            }
        })
    }, []);
    const bannerData = {
        imgurl: '../images/industries.jpg',
        title: whitepaper.whitepaper_title,
        // description: "This is Whitepaper page",
    };
    return (
        <>
            <InternalBanner{...bannerData} />
            <Container>
                <section class="py-5">
                    <div class="container">
                        <div class="row gx-5">
                            <aside class="col-lg-6">
                                <div class="border rounded-4 mb-3 d-flex justify-content-center">
                                    <img src={`${apiUrl}/public/Assets/Image/whitepaper/${whitepaper.whitepaper_image}`} alt="whitepaper" className="img-fluid" />
                                </div>
                            </aside>
                            <main class="col-lg-6">
                                <div class="ps-lg-3">
                                    {/* <h4 class="title text-dark">
                                        {whitepaper.whitepaper_title}
                                    </h4> */}

                                    {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: whitepaper.whitepaper_description }} /> */}

                                    {/* <hr /> */}

                                    <p>
                                        Duis dapibus placerat lorem, ut ullamcorper augue condimentum id. Integer iaculis nibh et odio consequat, in aliquam nunc tempor. Proin sed purus id mauris elementum ultrices id sed sem. Ut ullamcorper aliquam viverra. Nam vestibulum ex nec efficitur convallis. Sed consequat ante eu ante vestibulum pretium. Fusce rhoncus convallis dolor a molestie. Sed nulla lectus, pellentesque quis porta malesuada, egestas sit amet nulla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean lacinia nulla dignissim gravida elementum. Phasellus ultricies odio ac lorem molestie varius. Donec elementum a diam vitae tincidunt.
                                    </p>
                                    <div class="row mb-4">
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </section>

                <section class="py-4">
                    <div class="container">
                        <div class="row gx-4">
                            <div class="col-lg-12 mb-4">
                                <div class="border rounded-2 px-3 py-2 bg-white">
                                    <ul class="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
                                        <li class="nav-item d-flex" role="presentation">
                                            <a class="nav-link d-flex align-items-center justify-content-center w-100 active" id="ex1-tab-1" data-mdb-toggle="pill" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true">Additional Details</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="ex1-content">
                                        <div class="tab-pane fade show active" id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                                            <p>
                                                With supporting text below as a natural lead-in to additional content. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                                enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur.
                                            </p>
                                            <div class="row mb-2">
                                                <div class="col-12 col-md-6">
                                                    <ul class="list-unstyled mb-0">
                                                        <li><i class="fas fa-check text-success me-2"></i>Some great feature name here</li>
                                                        <li><i class="fas fa-check text-success me-2"></i>Lorem ipsum dolor sit amet, consectetur</li>
                                                        <li><i class="fas fa-check text-success me-2"></i>Duis aute irure dolor in reprehenderit</li>
                                                        <li><i class="fas fa-check text-success me-2"></i>Optical heart sensor</li>
                                                    </ul>
                                                </div>
                                                <div class="col-12 col-md-6 mb-0">
                                                    <ul class="list-unstyled">
                                                        <li><i class="fas fa-check text-success me-2"></i>Easy fast and ver good</li>
                                                        <li><i class="fas fa-check text-success me-2"></i>Some great feature name here</li>
                                                        <li><i class="fas fa-check text-success me-2"></i>Modern style and design</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    );

}

export default SingleWhite;