import React, { useState ,useEffect } from "react";
import InternalBanner from "../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import "../css/contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us | Tega Mcnally";
  }, []);

  const bannerData = {
    imgurl: "images/contact.jpg",

  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (value.length < 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Phone number should be at least 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "",
        }));
      }
    } else if (name === "email") {
      if (!value.includes(".com")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Email should contain ".com"',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await fetch('https://tega.codtrees.com/api/enquiryform', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log('Form submitted:', data);
    
        toast.success('Form submitted successfully!');
    
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Failed to submit form');
      }
    };
    

  return (
    <>
    <Helmet>
    <title>Contact Us | Tega Mcnally</title>
    <meta name="title" content="Contact Us | Tega Mcnally" />
    <meta name="description" content="Contact Us" />
</Helmet>
      <ToastContainer className="toastify_modal"/> 
    <div className="contact-page">
      <InternalBanner {...bannerData} />
      <div className="registered-office-section">
        <div className="registered-office-section-address">
          <h4 className="title">Registered Office</h4>
          <p className="text-center">
            16th floor, Tower-||, Godrej Water Side, Sec-V <br/>
            Salt Lake City, Kolkata - 700 091, West Bengal            
          </p>
        </div>
        <div className="registered-office-section-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117961.3995785009!2d88.26295624726109!3d22.49315985543527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0270c44347e505%3A0x3577e7c9968bdd0b!2sTega%20Industries%20Limited!5e0!3m2!1sen!2sin!4v1713442190871!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <Container>
        <div className="page-content">
          <Row className="column-gap">
            <Col md={3}>
              <div className="contact-card">
                <div className="space-between">
                  <div className="card-title">Bengaluru</div>
                  <div className="card-content">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <div className="content-text">
                      Plot.No. 313, Survey No 72 & 76, 3rd Phase, Alur Industrial Area, Malur Taluk, Kolar - 563130
                    </div>
                  </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248812.61952811794!2d77.75739552365981!3d12.991210062721182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae09ff98dee601%3A0xca4c75d5aae02bd8!2sMcnally%20Sayaji%20Engineering%20Ltd.!5e0!3m2!1sen!2sin!4v1720160945878!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
            <Col md={3}>
              <div className="contact-card">
                <div className="space-between">
                  <div className="card-title">Vadodara</div>
                  <div className="card-content">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <div className="content-text">
                      Savli Industrial Estate G I D C, <>Plot No. 75-79B,</> P.O. - Alindra, <>Dist. Vadodara</>, <br />Gujarat - 391775, India.
                    </div>
                  </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.606370001449!2d73.21203287521728!3d22.443836779583627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fcc58b0bd933f%3A0xcc39bd97ccf1ab60!2sSavli%20GIDC!5e0!3m2!1sen!2sin!4v1713442420225!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
            <Col md={3}>
              <div className="contact-card">
                <div className="space-between">
                  <div className="card-title">Kumardhubi</div>
                  <div className="card-content">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <div className="content-text">
                      P.O. Kumardhubi, Dist. Dhanbad, Jhankhand-828203, India
                    </div>
                  </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7304.2894504699925!2d86.77696899233501!3d23.742217964703823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f6dd9c389a6a6b%3A0x62014791816d0e8c!2sKumardhubi%20Sub%20Post%20Office!5e0!3m2!1sen!2sin!4v1713442499143!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
            <Col md={3}>
              <div className="contact-card">
                <div className="space-between">
                  <div className="card-title">Asansol</div>
                  <div className="card-content">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <div className="content-text">
                      Plot No. : M-16, ADDA Industrial Area, P.O.R K Mission Asansol -713305, West Bengal, India.
                    </div>
                  </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.5134328344325!2d86.93655058074178!3d23.693352323812906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f71fa9c15b4cff%3A0xb72502b67ab45e0a!2sMWVR%2B9H2%20ADDA%20Industrial%20Area%2C%20opp%3A%20GECPL%2C%20Kumarpur%2C%20Asansol%2C%20West%20Bengal%20713305!5e0!3m2!1sen!2sin!4v1713442545787!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
          </Row>
          <div className="colspac"></div>
          <Row>
            <iframe
              className="contact-map"
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d939133.3590275164!2d86.94919733218431!3d23.152321286549213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0270c44347e505%3A0x3577e7c9968bdd0b!2sTega%20Industries%20Limited!5e0!3m2!1sen!2sin!4v1707055139404!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Row>
          <Row>
            {/* Form */}
            <Col md={12} className="formcont">
              <h2 className="text-white mb-4">Enquiry Form</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.phone && (
                    <div className="text-danger">{errors.phone}</div>
                  )}
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="btn-c">
                  Submit
                </button>
              </form>
            </Col>
            {/* Map */}
          </Row>
        </div>
      </Container>
    </div>
  </>

  );
};

export default Contact;
