import React, { useState, useEffect } from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import { FaFilePdf } from 'react-icons/fa';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Brochures = () => {
  const [brochures, setBrochures] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handlePdfClick = (url) => {
    setSelectedPdf(url);
  };

  const bannerData = {
    imgurl: '/images/industries.jpg',
    // title: "Downloads",
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchBrochures = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/showbrochure`);
      const sortedBrochures = response.data.sort((a, b) => a.brochure_title.localeCompare(b.brochure_title));
      setBrochures(sortedBrochures);
      if (sortedBrochures.length > 0) {
        setSelectedPdf(`${apiUrl}/public/Assets/File/brochure/${sortedBrochures[0].brochure_file}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBrochures();
  }, []);

  return (
    <>
     <Helmet>
    <title>Downloads | Tega Mcnally</title>
    <meta name="title" content="Downloads | Tega Mcnally" />
    <meta name="description" content="Downloads" />
</Helmet>
      <InternalBanner {...bannerData} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={2}>
              <ul className="pdflist shadow">
                {brochures.map((brochure, index) => (
                  <li key={index}>
                    <Link onClick={() => handlePdfClick(`${apiUrl}/public/Assets/File/brochure/${brochure.brochure_file}`)}>
                      <FaFilePdf /> {brochure.brochure_title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={10}>
              {selectedPdf ? (
                <object className="fullwidpdf" data={selectedPdf} type="application/pdf">
                  <p>PDF could not be displayed</p>
                </object>
              ) : (
                <p>Please select a brochure from the sidebar</p>
              )}
            </Col>
            {/* <Col md={2}></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Brochures;
