import React from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../css/company_overview.css";

const ConpmanyOverview = () => {
  const bannerData = {
    imgurl: "images/comp_over_banner.jpg",
    title: "Company Overview",
    // description: "This is Company Overview page",
  };

  useEffect(() => {
    document.title = "Company Overview | Tega Mcnally";
  }, []);

  return (
    <>
      <InternalBanner {...bannerData} />

      <Container className="company_overview">
        <Row>
          <Col md={9}>
            <div className="colspac"></div>

            <Row>
              <Col>
                <h2 className="bbefore mt-2">
                  <span>The Legacy</span>
                </h2>

                <div class="company-overview">
                  <div class="image-container">
                    <img src="images/legacy.jpg" alt="Moved Image" />
                    <div class="overlay"></div>
                  </div>
                  <div class="content-container">
                    {/* <div class="title">Company Overview</div> */}
                    <div class="subtitle">
                      <p> Tega McNally Minerals Limited stands as a distinguished player in the realm of mineral
                          processing &amp; material handling solutions, with a legacy dating back to its establishment in 1961.
                          The company’s evolution has been marked by transformative milestones, consistent growth and
                          innovation and expansion of its global presence through strategic acquisitions, cutting edge
                          technologies and a commitment to excellence.
                          TMML stands at the forefront as a premier manufacturer of advanced equipments for crushing,
                          screening, feeding, grinding, and mineral processing. We cater to the vital sectors of iron ore,
                          coal, steel, zinc, copper limestone, rare earths and more. Our commitment to excellence drives
                          us to deliver innovative solutions, ensuring optimal performance for our clients in terms of
                          unparalleled quality and reliability in the mineral processing industry.
                          The product range includes various types of crushers (jaw, roll, impactor, hammer etc.), screens
                          (linear, circular, roller, flip flop etc.), feeders (apron, grizzly, reciprocating, vibro etc.), dry &amp; wet
                          grinding mills (ball, rod, etc.), various types of mineral beneficiation equipment like slurry
                          pumps, thickeners, floatation cells, filter press, scrubbers etc. The company also manufactures
                          for the construction sector, products such as mobile crushing &amp; screening plants, skid mounted
                          crushing plants etc.</p>
                    </div>

                  </div>
                </div>
              </Col>
            </Row>
            <div className="colspac"></div>

            <Row>
              <Col>
                <h2 className="bbefore">
                  <span>Forging a New Horizon</span>
                </h2>

                <div class="company-overview">
                  <div class="content-container">
                    {/* <div class="title">Company Overview</div> */}
                    <div class="subtitle">
                      <p>In 2023, Tega Industries made a significant stride in engineering excellence by the acquisition of
                          McNally Sayaji Engineering Limited, thereby, launching Tega McNally Minerals Limited. Valued
                          at Rs 165 crore, this strategic acquisition blended McNally Sayaji’s extensive five-decade legacy
                          in manufacturing diverse engineered products with Tega&#39;s pioneering expertise in the global
                          mining and mineral processing industries. This strategic move not only bolstered Tega&#39;s market
                          position but also demonstrated its unwavering commitment to pushing boundaries and setting
                          the stage for a new era of engineering brilliance. With a renewed focus on excellence, Tega
                          McNally Minerals Limited embarked on a transformative journey, leveraging its combined
                          strengths to redefine industry benchmarks and drive sustainable growth in the mining and
                          mineral processing sectors.</p>
                    </div>

                  </div>

                  <div class="image-container">
                    <img src="images/forging_horizon.jpg" alt="Moved Image" />
                    <div class="overlay overlay_row2"></div>
                  </div>

                </div>
              </Col>
            </Row>
            <div className="colspac"></div>

            <Row>
              <Col>
                <h2 className="bbefore">
                  <span>Our Promise</span>
                </h2>

                <div class="company-overview">
                  <div class="content-container2">
                    {/* <div class="title">Company Overview</div> */}
                    <div class="subtitle">
                      <p>At Tega McNally Minerals Limited, we uphold a steadfast commitment to delivering excellence,
                        efficiency &amp; empowerment in all aspects of our operations. Our promise revolves around the
                        core values that define our brand and guide our actions towards achieving our goals.<br/><br/>
                        <b>Excellence</b> is what we strive for in everything we do, from the quality of our products to the
                        level of service we provide to our clients. Our relentless pursuit of engineering excellence
                        ensures that our solutions meet and exceed the highest industry standards, setting benchmarks
                        for performance, reliability, and innovation.<br/><br/>
                        <b>Efficiency</b> is the cornerstone of our operations. We are committed to maximising efficiency in
                        our processes by optimising resources and delivering solutions that drive productivity and
                        profitability for our clients.<br/><br/>
                        <b>Empowerment</b> lies at the heart of our company ethos. We believe in empowering our clients,
                        employees, and stakeholders through our solutions, that overcome challenges, seize

                        opportunities, and achieve success in an ever-evolving marketplace. We invest in our people,
                        fostering a culture of learning, growth, and collaboration that empowers them to excel in their
                        roles and contribute to our collective success.</p>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h2 className="bbefore">
                  <span>Empowering Industry Excellence</span>
                </h2>

                <div class="company-overview">
                  <div class="content-container2">
                    {/* <div class="title">Company Overview</div> */}
                    <div class="subtitle">
                      <p>At Tega McNally Minerals Limited, our commitment to engineering excellence powers every
                        machine we manufacture. With a legacy of precision and innovation, we craft cutting-edge
                        solutions that redefine industry standards and drive operational efficiency. From concept to
                        creation, our relentless pursuit of excellence ensures that each product is engineered to
                        perfection, delivering unmatched performance, durability, and reliability on the factory floor.</p>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>
          </Col>

          <Col md={3} className="flter_co">
            <div className="flter_co-inner">
              <ul className="filter_ul">
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/Thumb_about.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">


                        <Link to={"/about"} style={{ color: "white" }}>
                          About us
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>

                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/thumb_team.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/our-team"} style={{ color: "white" }}>
                          Our Team
                        </Link>

                      </h5>
                    </div>
                  </div>
               
                </li>

                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/manu_fac.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        <Link to={"/manufacturing-facilities"} style={{ color: "white" }}>
                          Manufacturing Facilities
                        </Link>

                      </h5>
                    </div>
                  </div>
                  </li>
                
                <li>
                  <div className={"card"} style={{ width: "18rem", cursor: "pointer" }}>
                    <img src="images/thumb_client.jpg" className="card-img-top" alt="Kumardhubi Factory" />
                    <div className="card-body">
                      <h5 className="card-title text-center" >
                        <Link to={"/our-clients"} style={{ color: "white" }}>
                          Clientele
                        </Link>

                      </h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Col>

        </Row>
      </Container>





    </>
  );
};

export default ConpmanyOverview;
