import React, { useState, useEffect } from "react";
import InternalBanner from "../../components/global/InternalBanner";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../css/meida_image.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const MediaPage = () => {
  const bannerData = {
    imgurl: "/images/industries.jpg",
    // title: "Images",
  };

  const location = useLocation()
  const {cate_type=""} = location.state || '';

  const [selectedCategory, setSelectedCategory] = useState("product-showcase");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [media, setMedia] = useState([]);
  const [allMedia, setAllMedia] = useState([]); // To keep all media for refetching on subcategory click

  const handleCategoryClick = async (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory("");
    setMedia([]);
    setAllMedia([]); // Reset all media

    try {
      const response = await fetch(`https://tega.codtrees.com/api/admin/media/${category}`);
      if (!response.ok) {
        throw new Error("Failed to fetch subcategories and media");
      }
      const data = await response.json();
      console.log(data.media);

      const uniqueSubcategories = new Set();
      data.media.forEach((item) => uniqueSubcategories.add(item.media_name));

      const uniqueSubcategoryArray = Array.from(uniqueSubcategories);
      setSubCategories(uniqueSubcategoryArray);

      let defaultSubCategory = cate_type.length>2 ? cate_type : uniqueSubcategoryArray[0]

      setSelectedSubCategory(defaultSubCategory);

      setAllMedia(data.media);
      setMedia(data.media.filter((item) => item.media_name === defaultSubCategory));
    } catch (error) {
      console.error("Error fetching subcategories and media:", error.message);
      setSubCategories([]);
      setMedia([]);
    }
  };

  const handleSubCategoryClick = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setMedia(allMedia.filter((item) => item.media_name === subCategory));
  };

  useEffect(() => {
    handleCategoryClick("product-showcase");
  }, []);

  return (
    <>
    <Helmet>
    <title>Images | Tega Mcnally</title>
    <meta name="title" content="Images | Tega Mcnally" />
    <meta name="description" content="Images" />
</Helmet>
      <InternalBanner {...bannerData} />
      <Container id="portfolio" className="media_page">
        <Row>
          <Col md={3}>
            <div className="filter-container">
              <ul className="pdflist shadow">
                <div className="tile__title">Categories</div>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "product-showcase" && "active"}`}
                    onClick={() => handleCategoryClick("product-showcase")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Product Showcase
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "events-trade-shows" && "active"}`}
                    onClick={() => handleCategoryClick("events-trade-shows")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Events & Trade Shows
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "corporate-images" && "active"}`}
                    onClick={() => handleCategoryClick("corporate-images")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Corporate Images
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "corporate-social-responsibility" && "active"}`}
                    onClick={() => handleCategoryClick("corporate-social-responsibility")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Corporate Social Responsibility
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "employee-spotlight" && "active"}`}
                    onClick={() => handleCategoryClick("employee-spotlight")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Employee Spotlight
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "training-and-education" && "active"}`}
                    onClick={() => handleCategoryClick("training-and-education")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Training & Education
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "company-awards" && "active"}`}
                    onClick={() => handleCategoryClick("company-awards")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Company Awards
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "media-coverage" && "active"}`}
                    onClick={() => handleCategoryClick("media-coverage")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Media Coverage
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "certifications" && "active"}`}
                    onClick={() => handleCategoryClick("certifications")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Certifications
                  </Button>
                </li>
                <li>
                  <Button
                    className={`btn filter-button ${selectedCategory === "testimonials" && "active"}`}
                    onClick={() => handleCategoryClick("testimonials")}
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Testimonials
                  </Button>
                </li>
              </ul>
            </div>
          </Col>

          <Col md={6}>
            <Row>
              {media.map((item, index) => (
                <Col md={4} key={index} className="gallery_product col-md-4 filter">
                  <img
                    src={`https://tega.codtrees.com/public/Assets/Image/media/${item.media_image}`}
                    className="port-image"
                    alt={selectedCategory}
                  />
                </Col>
              ))}
            </Row>
          </Col>

          <Col md={3}>
            <div className="filter-container">
              <ul className="pdflist">
                <div className="tile__title">{selectedCategory.replace("-", " ")}</div>
                {subCategories.map((subCategory, index) => (
                  <li key={index}>
                    <Button
                      className={`btn sub-category-button filter-button ${selectedSubCategory === subCategory && "active"}`}
                      onClick={() => handleSubCategoryClick(subCategory)}
                    >
                      <i className="fa fa-angle-double-right" aria-hidden="true"></i> {subCategory}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MediaPage;
