import React from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";

const HomeAbout = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [histories, setHistory] = useState([]);
  const [missions, setMission] = useState([]);
  const [visions, setVision] = useState([]);

  const history = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/addhistory`);
      setHistory(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const mission = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/addmission`);
      setMission(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const vision = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/addvision`);
      setVision(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    history();
    mission();
    vision();
  }, []);

  return (
    <Container>
      <Col>
        <h3 className="bbefore">
          <span>About Us</span>
        </h3>
      </Col>
      <Row>
        <Col
          md={5}
          className="animate__animated animate__fadeInUp animate__slow animate__delay-1s"
        >
          {/* Graphics */}
          <img
            src="images/home_about.jpg"
            className="img-fluid brimg shadow abhome"
            alt="Home Graphic"
          />
        </Col>
        <Col
          md={7}
          className="tabsbt animate__animated animate__fadeInUp animate__slow animate__delay-1s"
        >
          <Tab.Container id="professional-tabs" defaultActiveKey="personal">
            <Row>
              <Col md={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="personal">About Us</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="bookings">Mission</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="reviews">Our Vision</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="personal">
                    {histories.map((history, index) => (
                      <>
                        <div className="p-3 shadow  bg-white">
                          
                          <p className="font-italic mb-2" style={{"fontSize": "25px"}}>
                            <div dangerouslySetInnerHTML={{ __html: history.history_description }}  />
                            {/* {history.history_description} */}
                          </p>
                          <div className="mt-4">
                          <Link to="/about" className="btn-c hcl">Read More &nbsp; <i class="fas fa-long-arrow-alt-right"></i> </Link>
                          </div>  
                        </div>
                      </>
                    ))}
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="bookings">
                  {missions.map((mission, index) => (
                      <>
                        <div className="p-3 shadow rounded bg-white">
                          
                          <p className="font-italic mb-2">
                            {mission.mission_description}
                          </p>
                        </div>
                      </>
                    ))}
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="reviews">
                  {visions.map((vision, index) => (
                      <>
                        <div className="p-3 shadow rounded bg-white">
                         
                          <p className="font-italic mb-2">
                            {vision.vision_description}
                          </p>
                        </div>
                      </>
                    ))}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeAbout;
