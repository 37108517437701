import React from "react";
import InternalBanner from "../components/global/InternalBanner";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../css/resources.css";
import { useRef, useState,useEffect } from "react";


const Resources = () => {
  const bannerData = {
    imgurl: "images/industries.jpg",
    title: "Insights",
    // description: "This is  Resources page",
  };

  useEffect(() => {
    document.title = "Resources | Tega Mcnally";
  }, []);

  return (
    <>
      <InternalBanner {...bannerData} />
      <Container>
        <div className="page-content">
          <Row>
            <Col md={3}>
              {/* <div className="case-study-box-simple">
                <Link to="/media">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">Media</h4>
                  </div>
                </Link>
              </div> */}
            </Col>
            <Col md={3}>
              {/* <div className="case-study-box-simple">
                <Link to="/news">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">News</h4>
                  </div>
                </Link>
              </div> */}
            </Col>
            <Col md={3}>
              {/* <div className="case-study-box-simple">
                <Link to="/events">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">Events</h4>
                  </div>
                </Link>
              </div> */}
            </Col>
            
            <Col md={3}>
              <div className="case-study-box-simple">
                {/* <Link to="/blogs">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">Blogs</h4>
                  </div>
                </Link> */}
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
          <Col md={3}> <div className="case-study-box-simple">
                <Link to="/events">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">Events</h4>
                  </div>
                </Link>
              </div></Col>
            <Col md={3}>
              <div className="case-study-box-simple">
                <Link to="/faq">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">FAQ's</h4>
                  </div>
                </Link>
              </div>
            </Col>
            
            <Col md={3}>
              <div className="case-study-box-simple">
                <Link to="/whitepaper">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">Whitepaper</h4>
                  </div>
                </Link>
              </div>
            </Col>
            <Col md={3}>
              <div className="case-study-box-simple">
                <Link to="/brochures">
                  <div className="content-overlay" />
                  <img
                    src="images/industrygraphic.jpg"
                    className="img-fluid content-image entered"
                    alt="Case Study"
                  />

                  <div className="content-details">
                    <h4 className="case-study-gaping">Brochures</h4>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
          
        </div>
      </Container>
    </>
  );
};

export default Resources;
